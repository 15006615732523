import React from "react";
import moment from "moment";
import { endOfTomorrow, format } from "date-fns";
import { NewTabbedTable } from "../../../../UI";
import Title from "../Title";

const Table = (props) => {
  console.log("props in table.jsx: ", props);  
  const { enrollmentTabName } = props;
  let headings = {
    Enrollments: [
      {
        id: "name",
        label: "Name",
        customCell: (r, i) => {
          return <td key={i}>{r.displayName}</td>;
        }
      },
      ...(props.addChild
        ? [
            {
              id: "childName",
              label: "Child Name",
              customCell: (r, i) => {
                return <td key={i}>{r.childName}</td>;
              }
            }
          ]
        : []),
      ...(props.prices 
        ? [
          {
            id: "end",
            label: "Term",
            customCell: (r, i) => {
              const plan = JSON.parse(props.prices[r.plan_id]);
              return (
                <td key={i}>
                  {plan.membership_term > 1
                    ? `Every ${plan.membership_term} Months`
                    : "Every Month"}
                </td>
              );
            }
          }
        ] : []
      ),
      ...(props.plans 
        ? [{
        id: "ptype",
        label: "Program Plan",
        customCell: (r, i) => {
          if (r.plan_id === null) {
            return <td key={i}>Auto Register</td>;
          }
          const plan = JSON.parse(
            props.plans.filter(
              e => JSON.parse(e).id == r.plan_id
            )[0]
          );
          const objConv = {
            week: "Weekly",
            "bi-week": "Bi-Weekly",
            month: "Monthly"
          };
          return (
            <td key={i}>
              {plan.type === "Semester"
                ? r.drop_in_dates
                  ? `Semester Drop-in - $${parseFloat(
                      (plan.total_price / plan.dayCount) *
                        (1 + parseInt(plan.dropin_interest) / 100)
                    ).toFixed(0)}`
                  : plan.installments && r.isSemesterInstallment
                  ? `Semester - Inst - $${parseFloat(
                      (plan.total_price / plan.installments_breakdown) *
                        (1 + parseInt(plan.installments_interest) / 100)
                    ).toFixed(0)} - ${plan.installments_plan}`
                  : plan.description && plan.description !== ""
                  ? `${plan.description} - $${plan.total_price}`
                  : `Semester - $${plan.total_price}`
                : plan.type === "Free"
                ? plan.description && plan.description !== ""
                  ? `Free - ${plan.description}`
                  : "Free"
                : plan.type === "Free Trial"
                ? plan.description && plan.description !== ""
                  ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
                  : `Free Trial (${plan.trialPeriod} classes)`
                : plan.type === "Single Sessions"
                ? plan.description && plan.description !== ""
                  ? `${plan.description} - $${plan.total_price} x (${r.userSelectedQuantity})`
                  : `Single Sessions - $${plan.total_price} x (${r.userSelectedQuantity})`
                : plan.type === "Drop-In"
                ? plan.description && plan.description !== ""
                  ? `${plan.drescription} - $${plan.total_price} x ${r.drop_in_dates.length}`
                  : `Drop-In - $${plan.total_price} x ${r.drop_in_dates.length}`
                : `Recurring - $${plan.tuition_rate} / ${
                    objConv[plan.billing_cycle]
                  }, \n ${
                    plan.isReplaceTimeEnabled
                      ? `${plan.description}`
                      : `${plan.timing[0]} to ${plan.timing[1]}`
                  }`}
            </td>
          );
        }
        }] 
        : props.tickets
        ? [{
          id: "start",
          label: "Ticket Type",
          customCell: (r, i) => {
            if (r.plan_id !== 0 && !r.plan_id) {
              return <td key={i}>Auto Register</td>;
            }
            const plan = JSON.parse(props.tickets[r.plan_id]);
            return <td key={i}>{plan.ticket_status}</td>;
          }
        }]
        : props.prices
        ? [{
            id: "end",
            label: "Subscription",
            customCell: (r, i) => {
              const plan = JSON.parse(props.prices[r.plan_id]);
              return <td key={i}>{plan.auto_renew ? "Yes" : "No"}</td>;
            }
          }]
        : []
      ),
      {
        id: "start",
        label: "Dates",
        customCell: (r, i) => {
          if (r.plan_id === null) {
            return <td key={i}></td>;
          }
          console.log(r);
          return (
            <td key={i}>
              {r.startDate &&
                moment(
                  new Date(
                    r.startDate.replace(/-/g, "/").replace(/T.+/, "")
                  )
                ).format("MMM DD, YYYY")}
              {r.drop_in_dates &&
                `${r.drop_in_dates
                  .map(e => format(new Date(e), "LLL dd, yyyy"))
                  .join(", ")}`}
              {r.partyDetails &&
                moment(
                  new Date(
                    r.partyDetails.startDate.replace(/-/g, "/").replace(/T.+/, "")
                  )
                ).format("MMM DD, YYYY")
              }
            </td>
          );
        }
      },
      {
        id: "end",
        label: "End Date",
        customCell: (r, i) => <td key={i}>{r.endDate ? format(new Date(r.endDate), "LLLL dd, yyyy") : "---"}</td>
      }
    ],
    Applications: [
      {
        id: "name",
        label: "Name",
        customCell: (r, i) => {
          return <td key={i}>{r.displayName}</td>;
        }
      },
      ...(props.addChild
        ? [
            {
              id: "childName",
              label: "Child Name",
              customCell: (r, i) => {
                return <td key={i}>{r.childName}</td>;
              }
            }
          ]
        : []),
      ...(props.prices 
        ? [
          {
            id: "end",
            label: "Term",
            customCell: (r, i) => {
              const plan = JSON.parse(props.prices[r.plan_id]);
              return (
                <td key={i}>
                  {plan.membership_term > 1
                    ? `Every ${plan.membership_term} Months`
                    : "Every Month"}
                </td>
              );
            }
          }, 
          {
            id: "end",
            label: "Subscription",
            customCell: (r, i) => {
              const plan = JSON.parse(props.prices[r.plan_id]);
              return <td key={i}>{plan.auto_renew ? "Yes" : "No"}</td>;
            }
          }
        ] : []
      ),
      {
        id: "date",
        label: "Application Date",
        customCell: (r, i) => {
          return <td key={i}>{format(new Date(r.createdAt), "LLLL dd, yyyy")}</td>
        }
      }
    ],
    Inactive: [
      {
        id: "name",
        label: "Name",
        customCell: (r, i) => {
          return <td key={i}>{r.displayName}</td>;
        }
      },
      ...(props.addChild
        ? [
            {
              id: "childName",
              label: "Child Name",
              customCell: (r, i) => {
                return <td key={i}>{r.childName}</td>;
              }
            }
          ]
        : []),
      {
        id: "endDate",
        label: "Inactive Date",
        customCell: (r, i) => (
          <td key={i}>
            {r.archivedDate ? format(new Date(r.archivedDate), "LLLL dd, yyyy") : format(new Date(r.updatedAt), "LLLL dd, yyyy")}
          </td>
        )
      }
    ]
  }

  headings[enrollmentTabName] = headings.Enrollments;
  let tabs = [enrollmentTabName , "Applications", "Inactive"];

  return (
    <div style={props.wrapperStyles}>
      <Title name={props.name} />
      <div className="tablecont" style={props.tableStyles}>
        <div className="table-responsive">
          <div
            style={{
              display: "inline-flex",
              flex: "1 1",
              border: "none",
              borderRadius: "10px",
              overflow: "hidden",
              width: "100%"
            }}
          >
    {    /*    <i
              className="fas fa-search"
              style={{ padding: "8px", background: "white" }}
            ></i>
            <input
              type="text"
              placeholder="Search by name"
              style={{
                border: "none",
                fontSize: "13px",
                padding: "5px",
                outline: 0,
                background: "white",
                flex: 1
              }}
              value={this.state.filter}
              onChange={e => {
                this.setState({ filter: e.target.value });
              }}
            />*/}
          </div>
          <NewTabbedTable
            inModal="true"
            tabs={tabs}
            headings={headings}
            data={props.customers}
          />
        </div>
      </div>
    </div>
  );
}

export default Table;