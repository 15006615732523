import React from "reactn";
import FranchiserEarningsModal from "../../../../components/modals/FranchiserEarningsModal";
import FranchiserEarningsOverview from "./FranchiserEarningsOverview";
import FranchiserEarningsByListingType from "./FranchiserEarningsByListingType";
import FranchiserRecentPayments from "./FranchiserRecentPayments";
import FranchiserTransactions from "./FranchiserTransactions";
import axios from "axios";
import "../headingStyle.css";
import { isBefore, isAfter, subDays, addDays, format } from "date-fns";

class FranchiserEarnings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partnerId: this.global.franchiseUser.selectedId,
      partnerOrg: {
        earnings: {},
        user: {}
      },
      totalEarnings: 0,
      totalTransactions: 0,
      orders: [],
      recentPayments: [],
      listingEarnings: []
    }
  }

    // including start, excluding end
    getAmountBetweenDates(start, end, arr) {
      let amount = 0;
      arr.forEach(e => {
        if (isBefore(e.date, end) && isAfter(e.date, subDays(start, 1))) {
          amount += parseInt(e.amount);
        }
      });
      return amount;
    }
  
    makeCumulative = transactions => {
      let cumulative = 0;
      return transactions.map(e => {
        cumulative += e.amount;
        return { ...e, amount: cumulative };
      });
    };
  
    newGroupByWeek = transactions => {
      if (transactions.length === 0) {
        return [];
      }
      let date = transactions[0].date;
      let endDate = transactions[transactions.length - 1].date;
      const result = [];
  
      while (isBefore(date, addDays(endDate, 7))) {
        const amount = this.getAmountBetweenDates(
          date,
          addDays(date, 7),
          transactions
        );
        const formattedDate = format(date, "LLL dd");
        result.push({ date: formattedDate, amount: parseInt(amount) });
        date = addDays(date, 7);
      }
  
      console.log("result is", result);
      return this.makeCumulative(result);
    };  
  
  async componentDidMount() {
    await this.fetchInitialData();
  }

  async componentDidUpdate() {
    if (this.state.partnerId !== this.global.franchiseUser.selectedId) {
      this.setState({partnerId: this.global.franchiseUser.selectedId}, async () => {
        await this.fetchInitialData();
      });
    }
  }

  async fetchInitialData() {
    this.setGlobal({loading: true});
    const ep1 = `${process.env.REACT_APP_API}/franchise/get-partner/${this.state.partnerId}`;
    const ep2 = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const ep3 = `${process.env.REACT_APP_API}/franchise/get-earnings/${this.state.partnerId}`;
    const res1 = await axios.get(ep1);
    let partner;
    if (res1.data.success) {
      partner = res1.data.data;
    }

    let earnings = {
      oneTime: 0,
      recurring: 0,
      refundedOneTime: 0,
      refundedRecurring: 0
    };
    let total = 0;
    let orders = [];
    let recentPayments = [];
    const res2 = await axios.get(ep2, { params: { partnerId: this.state.partnerId } });
    if (res2.data.success) {
      const orderData = res2.data.data;
      console.log("Order data", orderData);
      const oneTimeTotal = orderData.Once.payments.reduce(
        (prev, curr) => prev + parseInt(curr.total),
        0
      );

      const recurringTotal = orderData.Recurring.payments.reduce(
        (prev, curr) => prev + parseInt(curr.total),
        0
      );

      const refundedOneTime = orderData.Once.payments.reduce(
        (prev, curr) => prev + parseInt(curr.refundedAmount),
        0
      );

      const refundedRecurring = orderData.Recurring.payments.reduce(
        (prev, curr) => prev + parseInt(curr.refundedAmount),
        0
      );

      earnings = {
        oneTime: oneTimeTotal / 100,
        recurring: recurringTotal / 100,
        refundedOneTime: refundedOneTime / 100,
        refundedRecurring: refundedRecurring / 100
      };
      total = (oneTimeTotal + recurringTotal) / 100;
      recentPayments = [...orderData.Once.payments, ...orderData.Recurring.payments, ...orderData.Once.failed];
      orders = this.newGroupByWeek(
        [...orderData.Once.payments, ...orderData.Recurring.payments]
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(e => ({ date: new Date(e.date), amount: e.total }))
      );

    }
    
    this.setState({ 
      partnerOrg: { user: partner, earnings: earnings},
      totalEarnings: total,
      orders: orders,
      recentPayments: recentPayments
    });

    const res3 = await axios.get(ep3);

    if (res3.data.success) {
      this.setState({
        listingEarnings: res3.data.data
      });
    }
    this.setGlobal({loading: false});
  }

  render () {
    return (
      <>
        <div className="cont heading-wrapper">
          <h1 className="page-heading">Earnings Summary</h1>
        </div>
        <div className="container-fluid my_earning">
            <div className="cont">
              <FranchiserEarningsModal partnerOrg={this.state.partnerOrg} />
              <div className="row">
                <FranchiserEarningsOverview
                  total={this.state.totalEarnings}
                  earnings={this.state.orders}
                />
                <FranchiserTransactions transactions={this.state.recentPayments} totalEarnings={this.state.totalEarnings}/>
              </div>
              <div className="row">
                <FranchiserRecentPayments earnings={this.state.recentPayments} />
                <FranchiserEarningsByListingType earnings={this.state.listingEarnings} />
              </div>
            </div>
        </div>
      </>
    );
  }
};

export default FranchiserEarnings;