import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  TITLE,
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format, isAfter, addDays, addHours } from "date-fns";

class NudgeInvoiceModal extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    sendEmail = async () => {
        const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/send-email-to-user`;
        const res = await axios.post(ep, {
            id: this.props.selectedItem.id,
            invoice_message: this.props.selectedItem.invoiceMessage
        });

        if (res.data.success) {
            toast.success("Email sent");
            this.props.confirmNudge();
            this.props.onClose();
        } else {
            toast.success("Error occured while sending email");
            this.props.onClose();
        }
    }

    getBaseModalFields = () => {
        const confirmField = {
            type: TITLE,
            data: {
                name: `Re-send this invoice email to ${this.props.selectedItem.purchaser} (${this.props.selectedItem.email})?`
            }
        }

        //Only show this field when lastInvoiceNudgeTime is not null.
        const dateExists = {
            type: CONTENT_BLOCK,
            data: {
                content: `You may only nudge a user once per hour. Last nudge date: ${format(new Date(this.props.selectedItem.lastInvoiceNudgeDate), 'PPpp')}`
            }
        }

        const fields = [];

        fields.push(confirmField);

        if (this.props.selectedItem.lastInvoiceNudgeDate !== null) {
            fields.push(dateExists);
        }

        return fields;
    }

    getBaseModalButtons = () => {
        const nudgeCheckDateButton = {
            name: "Nudge",
            buttonColour: BLUE_BUTTON,
            textColour: WHITE,
            isDisabled: (this.props.selectedItem.lastInvoiceNudgeDate !== null && isAfter(new Date(), addHours(new Date(this.props.selectedItem.lastInvoiceNudgeDate), 1))) ? false : true,
            handleClick: () => this.sendEmail()
        };

        const nudgeButton = {
            name: "Nudge",
            buttonColour: BLUE_BUTTON,
            textColour: WHITE,
            isDisabled: false,
            handleClick: () => this.sendEmail()
        };

        const buttons = [];

        if (this.props.selectedItem.lastInvoiceNudgeDate !== null) {
            buttons.push(nudgeCheckDateButton);
        } else {
            buttons.push(nudgeButton);
        }
        
        return buttons;
    }

    getBaseModalProps = () => {
        return {
            title: "Nudge User",
            fields: this.getBaseModalFields(),
            buttons: this.getBaseModalButtons(),
            height: "65vh",
            midSectionHeight: "52vh",
            handleClose: () => this.props.onClose()
          };
    }

    render() {
        return <BaseModal {...this.getBaseModalProps()}/>
    }
}

export default withRouter(NudgeInvoiceModal);