import React, { useContext } from "react";

import {
  PromotionContext,
  actions,
  discountTypes,
  discountDurationTypes
} from "../promotion.context";

import { Dropdown } from "semantic-ui-react";

import { BoxForm } from "../../../../components/UI/form";

const SectionOne = () => {
  const { state, dispatch } = useContext(PromotionContext);
  const customForm = () => {
    return (
      <div className="formbody">
        {/* Title */}
        <div className="row">
          <div className="col-5">
            <h3 className="label-names">
              {" "}
              <label htmlFor="title">
                Title
                <RequiredStar />
              </label>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div className="w-100">
              <input
                value={state.title}
                data-testid="title"
                onChange={e =>
                  dispatch({
                    type: actions.UPDATE_TITLE,
                    payload: e.target.value
                  })
                }
                className="input-fields w-100"
                type="text"
                id="title"
              />
            </div>
          </div>
        </div>
        {/* Program Description */}
        <div className="row">
          <div className="col-5">
            <h3 className="label-names">
              <label htmlFor="program-description">
                Promotion Description <RequiredStar />
              </label>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div className="w-100">
              <textarea
                className="input-fields w-100"
                data-testid="description"
                onChange={e =>
                  dispatch({
                    type: actions.UPDATE_DESCRIPTION,
                    payload: e.target.value
                  })
                }
                value={state.description}
                id="program-description"
                aria-label="program description"
              ></textarea>
            </div>
          </div>
        </div>
        {/* Discount Type */}

        <div className="row">
          <div className="col-5">
            <h3 className="label-names">
              <label htmlFor="discount-type">
                Discount Type <RequiredStar />
              </label>
            </h3>
          </div>
          <div className="col-7 d-flex flex-row">
            <div className="w-100">
              {/* Select Discount Type */}
              {state.id ? (
                <>
                  <Dropdown
                    placeholder="Pick a Percentage Type"
                    fluid
                    disabled
                    selection
                    value={state.discountType}
                    options={[
                      {
                        value: discountTypes.PERCENTAGE,
                        text: "Percentage (%)",
                        key: discountTypes.PERCENTAGE
                      },
                      {
                        value: discountTypes.FIXED_AMOUNT,
                        text: "Fixed Amount ($)",
                        key: discountTypes.FIXED_AMOUNT
                      }
                    ]}
                  />
                  <input
                    type="number"
                    disabled
                    value={
                      state.discountType === "Percentage"
                        ? state.discountAmount
                        : (state.discountAmount / 100).toFixed(2)
                    }
                    id="discount-amount"
                    className="w-100 mt-2 input-fields"
                    aria-label="Discount Amount"
                  />
                </>
              ) : (
                <>
                  <Dropdown
                    placeholder="Pick a Percentage Type"
                    fluid
                    selection
                    onChange={(_, { value }) => {
                      dispatch({
                        type: actions.UPDATE_DISCOUNT_TYPE,
                        payload: value
                      });
                    }}
                    value={state.discountType}
                    options={[
                      {
                        value: discountTypes.PERCENTAGE,
                        text: "Percentage (%)",
                        key: discountTypes.PERCENTAGE
                      },
                      {
                        value: discountTypes.FIXED_AMOUNT,
                        text: "Fixed Amount ($)",
                        key: discountTypes.FIXED_AMOUNT
                      }
                    ]}
                  />
                  <input
                    type="number"
                    onChange={e => 
                      dispatch({
                        type: actions.UPDATE_DISCOUNT_AMOUNT,
                        payload: state.discountType === discountTypes.PERCENTAGE ? Math.min(Math.max(0, e.target.value), 100) : Math.max(0, e.target.value)
                      })
                    }
                    value={state.discountAmount}
                    id="discount-amount"
                    className="w-100 mt-2 input-fields"
                    aria-label="Discount Amount"
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* Duration Applied */}
        {shouldDurationAppliedBeDisplayed(state) ? (
          <></>
        ) : (
          <>
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  {" "}
                  <label htmlFor="duration-applied">Discount Applied</label>
                </h3>
              </div>
              <div className="col-7 d-flex flex-row">
                <div className="w-100">
                  {state.id ? (
                    <>
                      <Dropdown
                        placeholder="Pick an Applied Type"
                        fluid
                        selection
                        disabled
                        value={state.durationApplied}
                        options={[
                          {
                            text: "Once",
                            value: discountDurationTypes.ONCE
                          },
                          {
                            text: "Forever",
                            value: discountDurationTypes.FOREVER
                          },
                          {
                            text: "Multiple Months",
                            value: discountDurationTypes.MULTIPLE_MONTHS
                          }
                        ]}
                      />
                      {state.durationApplied ===
                        discountDurationTypes.MULTIPLE_MONTHS && (
                        <div className="d-flex flex-row w-100 mt-2">
                          <input
                            disabled
                            value={state.months}
                            type="number"
                            id="months-discount"
                            className="input-fields w-100"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Dropdown
                        placeholder="Pick an Applied Type"
                        fluid
                        selection
                        onChange={(_, { value }) => {
                          dispatch({
                            type: actions.UPDATE_DURATION_APPLIED,
                            payload: value
                          });
                        }}
                        value={state.durationApplied}
                        options={[
                          {
                            text: "Once",
                            value: discountDurationTypes.ONCE
                          },
                          {
                            text: "Forever",
                            value: discountDurationTypes.FOREVER
                          },
                          {
                            text: "Multiple Months",
                            value: discountDurationTypes.MULTIPLE_MONTHS
                          }
                        ]}
                      />
                      {state.durationApplied ===
                        discountDurationTypes.MULTIPLE_MONTHS && (
                        <div className="d-flex flex-row w-100 mt-2">
                          <input
                            onChange={e =>
                              dispatch({
                                type: actions.UPDATE_DURATION_MONTHS,
                                payload: e.target.value
                              })
                            }
                            value={state.months}
                            type="number"
                            id="months-discount"
                            className="input-fields w-100"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  const handleChange = () => {};
  return (
    <BoxForm
      handleChange={handleChange}
      name={"Promotion Setup"}
      customForm={customForm()}
    />
  );
};

// returns true if not displayed
function shouldDurationAppliedBeDisplayed(state) {
  // event: do not display (due to one time charge)
  const event_display = state.promotion_type === "event";
  // membership: do not display if auto-renew type plan is not selected (becuase auto-renew are subscriptions and they require duration applied settigns)
  const membership_display =
    state.promotion_type === "membership" &&
    state.selectedPrices.length != 0 &&
    !state.selectedPrices[0].auto_renew;
  return event_display || membership_display;
}

function RequiredStar() {
  return <span className="requiredField">*</span>;
}

SectionOne.propTypes = {};

export default SectionOne;
