import React, { useState, useEffect, useGlobal } from "reactn";
import { CircleCheck } from "../../assets";
import axios from "axios";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { canUserCreate } from "../../utilities/listingCreation";

import { SWITCH, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";

const CreateEventModal = ({ onClose, history, dispatch }) => {
  const [addChild, setAddChild] = useState(false);
  const [manualInvoices, setManualInvoices] = useState(false);
  const [applicationRequired, setApplicationRequired] = useState(false);
  const [birthdayEvent, setBirthdayEvent] = useState(false);
  const [displayManualInvoices, setDisplayManualInvoices] = useState(false);
  const [canUserMake, setCanUserMake] = useState(false);
  const [allowSameEnrollment, setAllowSameEnrollment] = useState(true);
  const [loading, setLoading] = useGlobal("loading");
  const [transactionCost, setTransactionCost] = useState(false);

  async function checkManual() {
    const feature_category = "Customization";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      // return resp.data
      const customizationPermissions = resp.data.data.filter(
        e => e.feature === "Accept Manual Payments"
      );
      const checkManual =
        customizationPermissions.length > 0
          ? customizationPermissions[0].has_access
          : false;
      setDisplayManualInvoices(checkManual);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch.resetEvent();
      await checkManual();

      // Check if user can create more listings.
      const resp = await canUserCreate("event");
      setCanUserMake(resp.success);
      setLoading(false);
    })();
  }, []);

  const getBaseModalFields = () => {
    const targetChildren = {
      type: SWITCH,
      data: {
        name: "Target Children",
        checked: addChild,
        handleClick: () => setAddChild(!addChild)
      }
    };

    // Oct 13, 2021: Hiding Event Offline/Manual Payment Option
    // const offlinePayment = {
    //   type: SWITCH,
    //   data: {
    //     name: "Offline Payment",
    //     checked: manualInvoices,
    //     handleClick: () => setManualInvoices(!manualInvoices),
    //     info: "Allows your client to pass through checkout without paying online"
    //   }
    // };

    const applicationRequiredField = {
      type: SWITCH,
      data: {
        name: "Application Required",
        checked: applicationRequired,
        handleClick: () => setApplicationRequired(!applicationRequired)
      }
    };

    const shareTransactionFeeField = {
      type: SWITCH,
      data: {
        name: "Share Transaction Fee",
        checked: transactionCost,
        required: false,
        handleClick: () => setTransactionCost(!transactionCost),
        isDisabled: manualInvoices,
        info: "A smaller percentage fee will be passed on to customers"
      }
    };

    const allFields = [];
    allFields.push(targetChildren);
    // if (displayManualInvoices) {
    //   allFields.push(offlinePayment);
    // }
    allFields.push(applicationRequiredField);
    allFields.push(shareTransactionFeeField);

    return allFields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: () => {
        history.push({
          pathname: "/partners/events/setup",
          state: {
            addChild: addChild,
            manualInvoices: manualInvoices,
            applicationRequired: applicationRequired,
            allowSameEnrollment: allowSameEnrollment,
            birthdayEvent: birthdayEvent,
            transactionCost: transactionCost
          }
        });
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Create Event",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  const getLimitReachedModal = () => {
    const props = {
      title: "Create Event",
      fields: [
        { type: TITLE, data: { name: "You have reached your event limit." } }
      ],
      handleClose: onClose
    };
    return <BaseModal {...props} />;
  };

  return canUserMake ? (
    <BaseModal {...getBaseModalProps()} />
  ) : (
    getLimitReachedModal()
  );
};

export default CreateEventModal;
