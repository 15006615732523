import React, { useContext } from "react";
// import PropTypes from 'prop-types'
import Switch from "@material-ui/core/Switch";
import Select from "react-dropdown-select";
import { format, addDays } from "date-fns";

import {
  PromotionContext,
  actions,
  set_selected_tickets_action,
  set_selected_prices_action,
  set_seleted_plans_action
} from "../promotion.context";

import {
  BoxForm
  // formTypes
} from "../../../../components/UI/form";

const getTodaysDate = () => {
  const minDate = format(addDays(new Date(), 1), "yyyy-MM-dd");
  return minDate;
};
function RequiredStar() {
  return <span className="requiredField">*</span>;
}

const SectionTwo = () => {
  const { state, dispatch } = useContext(PromotionContext);
  console.log("state", state);

  const customForm = () => {
    return (
      <div className="formbody">
        <div className="row">
          <div className="col-5">
            <h3 className="label-names">
              <label>Expiry Date</label>
            </h3>
          </div>
          <div className="col-7 d-flex flex-row ">
            <div className="w-100">
              {state.id ? (
                <>
                  <Switch value={state.expiryDate ? true : false} disabled />
                  {state.expiryDate && (
                    <>
                      <input
                        value={state.expiryDate}
                        type="date"
                        className="input-fields"
                        disabled
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Switch
                    value={state.isExpirySet}
                    onChange={() => {
                      dispatch({
                        type: actions.UPDATE_SET_EXPIRY_DATE,
                        payload: !state.isExpirySet
                      });
                    }}
                  />
                  {state.isExpirySet && (
                    <>
                      <input
                        value={state.expiryDate}
                        onChange={e =>
                          dispatch({
                            type: actions.UPDATE_EXPIRY_DATE,
                            payload: e.target.value
                          })
                        }
                        type="date"
                        className="input-fields"
                        min={getTodaysDate()}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3 className="label-names">
              {" "}
              <label>Limit Promo Usage</label>
            </h3>
          </div>
          <div className="col-7 d-flex flex-row">
            <div className="w-100">
              {state.id ? (
                <>
                  <Switch value={state.isLimitPromoCode} disabled />

                  {state.isLimitPromoCode && (
                    <div className="w-100 mt-2">
                      <input
                        value={state.limitPromoCode}
                        disabled
                        type="number"
                        className="input-fields w-100"
                        min="1"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Switch
                    value={state.isLimitPromoCode}
                    onChange={() =>
                      dispatch({
                        type: actions.UPDATE_SET_PROMO_USAGE,
                        payload: !state.isLimitPromoCode
                      })
                    }
                  />

                  {state.isLimitPromoCode && (
                    <div className="w-100 mt-2">
                      <input
                        value={state.limitPromoCode}
                        onChange={e =>
                          dispatch({
                            type: actions.UPDATE_PROMO_USAGE,
                            payload: e.target.value
                          })
                        }
                        type="number"
                        className="input-fields w-100"
                        min="1"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="row">
                    <div className="col-5">
                        <h3 className="label-names"><label>Custom Bill Name</label></h3>
                    </div>
                    <div className="col-7 d-flex flex-row">
                        <div className="w-100">
                            {state.id ? (<>
                                <input
                                    value={state.customBillName}
                                    disabled
                                    type="text"
                                    className="input-fields w-100"
                                />
                            </>) :

                                (<>
                                    <input
                                        value={state.customBillName}
                                        onChange={(e) => dispatch({ type: actions.UPDATE_CUSTOM_BILL_NAME, payload: e.target.value })}
                                        type="text"
                                        className="input-fields w-100"
                                    />
                                </>)}
                        </div>
                    </div>
                </div> */}

        <div className="row">
          {state.promotion_type === "program" && (
            <>
              <div className="col-5">
                <h3 className="label-names">
                  {" "}
                  <label>
                    Plans
                    <RequiredStar />
                  </label>
                </h3>
              </div>
              <div className="col-7">
                {state.id ? (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      disabled
                      values={getNameValuePairForPlans(state.selectedPlans)}
                      options={getNameValuePairForPlans(state.plans)}
                    />
                  </>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForPlans(state.selectedPlans)}
                      options={getNameValuePairForPlans(state.plans)}
                      onChange={arrayOfValues => {
                        const the_plns = getPlanBasedOnNameValuePair(
                          arrayOfValues,
                          state.plans
                        );
                        set_seleted_plans_action(the_plns, dispatch);
                        return arrayOfValues;
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}
          {state.promotion_type === "party" && (
            <>
              <div className="col-5">
                <h3 className="label-names">
                  <label>
                    Party Tickets
                    <RequiredStar />
                  </label>
                </h3>
              </div>
              <div className="col-7">
                {state.id ? (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForTickets(state.selectedTickets)}
                      options={getNameValuePairForTickets(state.tickets)}
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForTickets(state.selectedTickets)}
                      options={getNameValuePairForTickets(state.tickets)}
                      onChange={arrayOfValues => {
                        const the_tkts = getTicketsBasedOnNameValuePair(
                          arrayOfValues,
                          state.tickets
                        );

                        set_selected_tickets_action(the_tkts, dispatch);
                        return arrayOfValues;
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}
          {state.promotion_type === "event" && (
            <>
              <div className="col-5">
                <h3 className="label-names">
                  <label>
                    Event Tickets
                    <RequiredStar />
                  </label>
                </h3>
              </div>
              <div className="col-7">
                {state.id ? (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForTickets(state.selectedTickets)}
                      options={getNameValuePairForTickets(state.tickets)}
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForTickets(state.selectedTickets)}
                      options={getNameValuePairForTickets(state.tickets)}
                      onChange={arrayOfValues => {
                        const the_tkts = getTicketsBasedOnNameValuePair(
                          arrayOfValues,
                          state.tickets
                        );

                        set_selected_tickets_action(the_tkts, dispatch);
                        return arrayOfValues;
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}

          {state.promotion_type === "membership" && (
            <>
              <div className="col-5">
                <h3 className="label-names">
                  <label>
                    Membership Prices
                    <RequiredStar />
                  </label>
                </h3>
              </div>
              <div className="col-7">
                {state.id ? (
                  <>
                    <Select
                      className="w-100"
                      placeholder="Select..."
                      multi
                      disabled
                      values={getNameValuePairForPrices(state.selectedPrices)}
                      // filter out the auto-renew if only the auto-renew plans are selected
                      options={
                        state.selectedPrices.length == 0
                          ? getNameValuePairForPrices(state.prices)
                          : state.selectedPrices[0].auto_renew
                          ? getNameValuePairForPrices(
                              state.prices.filter(prc => prc.auto_renew)
                            )
                          : getNameValuePairForPrices(
                              state.prices.filter(prc => !prc.auto_renew)
                            )
                      }
                    />
                  </>
                ) : (
                  <>
                    <Select
                      className="w-100"
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForPrices(state.selectedPrices)}
                      // filter out the auto-renew if only the auto-renew plans are selected
                      options={
                        state.selectedPrices.length == 0
                          ? getNameValuePairForPrices(state.prices)
                          : state.selectedPrices[0].auto_renew
                          ? getNameValuePairForPrices(
                              state.prices.filter(prc => prc.auto_renew)
                            )
                          : getNameValuePairForPrices(
                              state.prices.filter(prc => !prc.auto_renew)
                            )
                      }
                      onChange={arrayOfValues => {
                        const the_prcs = getPricesBasedOnNameValuePair(
                          arrayOfValues,
                          state.prices
                        );
                        set_selected_prices_action(the_prcs, dispatch);
                        return arrayOfValues;
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}
          {state.promotion_type === "online" && (
            <>
              <div className="col-5">
                <h3 className="label-names">
                  {" "}
                  <label>
                    Plans
                    <RequiredStar />
                  </label>
                </h3>
              </div>
              <div className="col-7">
                {state.id ? (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      disabled
                      values={getNameValuePairForOnlinePlans(
                        state.selectedPlans
                      )}
                      options={getNameValuePairForOnlinePlans(state.plans)}
                    />
                  </>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      multi
                      values={getNameValuePairForOnlinePlans(
                        state.selectedPlans
                      )}
                      options={getNameValuePairForOnlinePlans(state.plans)}
                      onChange={arrayOfValues => {
                        const the_plns = getPlanBasedOnNameValueOnlinePair(
                          arrayOfValues,
                          state.plans
                        );
                        set_seleted_plans_action(the_plns, dispatch);
                        return arrayOfValues;
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                <label>Display Promo Plans Only</label>
              </h3>
            </div>
            <div className="col-7 d-flex flex-row ">
              <div className="w-100">
                <Switch
                  checked={state.show_promo_only}
                  onChange={() => {
                    dispatch({
                      type: actions.UPDATE_SET_PROMO_ONLY,
                      payload: !state.show_promo_only
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleChange = () => {};
  return (
    <BoxForm
      handleChange={handleChange}
      name={"Promotion Detail Setup"}
      customForm={customForm()}
    />
  );
};

function getNameValuePairForPlans(plans) {
  return plans
    .filter(f => f.type !== "Free" && !f.archived)
    .map(plan => {
      const dropin = plan.dropIns ? " - Drop-Ins" : null;
      const installments = plan.installments ? " - Installment" : null;
      const pln_to_str =
        plan.type === "Semester"
          ? `Semester ($${plan.total_price})${
              dropin ? dropin : installments ? installments : ""
            } ${plan.active === false ? "(Inactive)" : ""}`
          : plan.type === "Single Sessions"
          ? `Single Sessions - $${plan.total_price} ${
              plan.active === false ? "(Inactive)" : ""
            }`
          : plan.type === "Drop-In"
          ? `Drop-In - $${plan.total_price} ${plan.active === false ? "(Inactive)" : ""}` 
          : `$${plan.tuition_rate} / ${plan.billing_cycle} ${
              plan.active === false ? "(Inactive)" : ""
            }`;
      return {
        label: pln_to_str,
        value: plan.id
      };
    });
}

function getPlanBasedOnNameValuePair(nameValuePairPlans, plans) {
  const result = [];
  for (let i = 0; i < nameValuePairPlans.length; i += 1) {
    const nvpair = nameValuePairPlans[i];
    for (let j = 0; j < plans.length; j += 1) {
      const plan = plans[j];
      if (plan.id === nvpair.value) {
        result.push({ ...plan });
      }
    }
  }
  return result;
}

function getNameValuePairForOnlinePlans(plans) {
  const objConv = {
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly"
  };

  return plans
    .filter(f => f.type !== "Free" && !f.archived)
    .map(plan => {
      const type = plan.type;
      const dropin = plan.dropIns ? " - Drop-Ins" : null;

      let pln_to_str = "";
      if (!type) {
        pln_to_str = `Recurring - ${plan.tuition_rate} / ${
          objConv[plan.billing_cycle]
        }`;
      } else {
        pln_to_str = `${type} ($${plan.total_price}) ${dropin ? dropin : ""} ${
          plan.active === false ? "(Inactive)" : ""
        }`;
      }
      return {
        label: pln_to_str,
        value: plan.id
      };
    });
}

function getPlanBasedOnNameValueOnlinePair(nameValuePairPlans, plans) {
  const result = [];
  for (let i = 0; i < nameValuePairPlans.length; i += 1) {
    const nvpair = nameValuePairPlans[i];
    for (let j = 0; j < plans.length; j += 1) {
      const plan = plans[j];
      if (plan.id === nvpair.value) {
        result.push({ ...plan });
      }
    }
  }
  return result;
}

function getNameValuePairForPrices(prices) {
  const prcs = prices
    .filter(f => !f.archived)
    .map(prc => {
      if (prc.auto_renew) {
        return {
          label: `$${prc.price_per_term}/term ${
            prices.active === false ? "(Inactive)" : ""
          }`,
          value: prc.id
        };
      }
      return {
        label: `$${prc.price_per_term} ${
          prices.active === false ? "(Inactive)" : ""
        }`,
        value: prc.id
      };
    });
  return prcs;
}

function getPricesBasedOnNameValuePair(nameValuePairPrices, prices) {
  const result = [];
  for (let i = 0; i < nameValuePairPrices.length; i += 1) {
    const nvpair = nameValuePairPrices[i];
    for (let j = 0; j < prices.length; j += 1) {
      const prc = prices[j];
      if (prc.id === nvpair.value) {
        result.push({ ...prc });
      }
    }
  }
  return result;
}

function getNameValuePairForTickets(tickets) {
  const tkts = tickets
    .filter(f => f.ticket_status !== "Free" && !f.archived)
    .map(tikt => {
      const ticket_price = tikt.ticket_price
        ? ` ($${tikt.ticket_price}) ${
            tickets.active === false ? "(Inactive)" : ""
          }`
        : "";
      return {
        label: `${tikt.ticket_name}${ticket_price} ${
          tickets.active === false ? "(Inactive)" : ""
        }`,
        value: tikt.id
      };
    });
  return tkts;
}

function getTicketsBasedOnNameValuePair(nameValuePairTickets, tickets) {
  const result = [];
  for (let i = 0; i < nameValuePairTickets.length; i += 1) {
    const nvPair = nameValuePairTickets[i];
    for (let j = 0; j < tickets.length; j += 1) {
      const tkt = tickets[j];
      if (tkt.id === nvPair.value) {
        result.push({ ...tkt });
      }
    }
  }
  return result;
}

SectionTwo.propTypes = {};

export default SectionTwo;
