import React from "reactn";
import { LiveView1, LiveView2, LiveView3 } from "../../assets";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import Checkout from "./checkout";
import { OrangePin } from "../../assets";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/css/componentSpecificCss/cssforProgram.css";
import { DateTime } from "luxon";
import { getFormattedDateTimeRange } from "./SpecificPageUtils/utils";
import { ReactBnbGallery } from "react-bnb-gallery";
import Radio from "@material-ui/core/Radio";
import SummarySubTotalEvents from "./SpecificPageUtils/SummarySubTotalEvents";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

class SpecificEvent extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      textShown: false,
      mapShown: false,
      gallery: [],
      event: {
        event_end: null,
        event_start: null,
        target_ages: [0, 0],
        tickets: [],
        other_locations: []
      },
      selected: null,
      checkout: false,
      eventPricing: 0
    };
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/search/event/${this.props.match.params.id}`;
    const results = await axios.get(ep);

    const gallery_ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
    const gallery = await axios.get(gallery_ep);

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }
    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/partners");
      this.setGlobal({ loading: false });
      return;
    }

    this.setState({
      event: results.data.data,
      gallery: gallery.data?.data?.data?.gallery ? gallery.data?.data?.data?.gallery : []
    });
    this.setGlobal({ loading: false });
  }

  getLocation(locations) {
    const data = locations.filter(e => e.address === this.state.event.address1);
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.event.main_lat}
        lng={this.state.event.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  render() {
    console.assert(
      this.state.event.timezone,
      "timezone not provided for partner/educator!"
    );

    const eventStart = DateTime.fromISO(this.state.event.event_start);
    const eventEnd = DateTime.fromISO(this.state.event.event_end);
    const rezonedStart = eventStart.setZone(this.state.event.timezone);
    const rezonedEnd = eventEnd.setZone(this.state.event.timezone);
    const currTime = DateTime.local();
    const rezonedCurrTIme = currTime.setZone(this.state.event.timezone);

    /* Events are a single block of time.
       e.g. Rock Concert: Jan 20, 9pm to Jan 21, 1am.   <-- it starts late, and rolls over to the next day.
            Not, Jan 20-Jan 21, 9pm to 1am.
       Representations:
        Jan 20, 2021 - 9pm to 11pm EST                    <-- start and end time are same day.
        Jan 20, 2021, 9:00pm - Jan 21, 2021, 1:00am EST   <-- start and end time are different days
    */
    let formattedDateTimeRange = getFormattedDateTimeRange(
      rezonedStart,
      rezonedEnd
    );

    let noPlansAvailableMessage;

    const eventEndDatePassed = this.state.event.continuous_registration
      ? new Date(rezonedEnd.ts) < new Date(rezonedCurrTIme)
      : new Date(rezonedStart.ts) < new Date(rezonedCurrTIme);

    if (eventEndDatePassed) {
      if (this.state.event.continuous_registration) {
        noPlansAvailableMessage = "- End date has passed";
      } else {
        noPlansAvailableMessage = "- Start date has passed";
      }
    }

    if (!this.global.organizationInfo) {
      return null;
    }

    return this.state.checkout ? (
      <Checkout
        form={this.state.event.formId}
        program={this.state.event}
        selected={this.state.selected}
        organization={this.state.event.organization_title}
        close={_ => this.setState({ checkout: false })}
        type={"Event"}
      />
    ) : (
      <div className="containerall">
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">{this.state.event.event_title}</h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                className="org-title"
                onClick={_ => this.props.history.push(`/preview`)}
              >
                {this.state.event.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={this.openGallery}
                >
                  Gallery
                </button>
                <button
                  className="partnerbutton"
                  style={{ marginRight: "10px" }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="event"
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.event.event_image}`}
            />
            <div
              className="widgetdata"
              style={{
                textAlign: "center",
                marginLeft: "50px"
              }}
            >
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView1} alt="icon" className="icon-img" />
                <p className="icon-text">{formattedDateTimeRange}</p>
              </div>

              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView2} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.event.all_ages
                    ? "All Ages"
                    : `Ages: ${this.state.event.target_ages[0]} to${" "}
                  ${this.state.event.target_ages[1]}`}
                </p>
              </div>
            </div>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Event Description</h3>

            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {this.state.event.description}
            </p>
          </div>

          {this.state.event.show_cancellation_policy && (
            <div className="box-size">
              <h3 className="sub-title">Cancellation Policy</h3>
              <p
                style={{
                  fontWeight: "400",
                  fontFamily: `"Open sans", sans-serif`
                }}
              >
                {this.state.event.cancellation_policy}
              </p>
            </div>
          )}

          <div className="box-size">
            <h3 className="sub-title">Location</h3>
            <div className="map-size">
              <p>Address : {this.state.event.address1}</p>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDVNuWNguQz4vB25OdDpztwxE3QR_CzG0g"
                }}
                defaultZoom={9}
                defaultCenter={{
                  lat: parseFloat(this.global.organizationInfo.main_lat),
                  lng: parseFloat(this.global.organizationInfo.main_long)
                }}
                options={{ gestureHandling: "greedy" }}
              >
                {this.getLocation(this.state.event.other_locations)}
              </GoogleMapReact>
            </div>
          </div>
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Select Plan</h2>

          <div style={{ width: "80%", margin: "0 auto" }}>
            {eventEndDatePassed && (
              <div>
                <p>No plans available {noPlansAvailableMessage}</p>
              </div>
            )}
            {!eventEndDatePassed &&
              this.state.event.tickets.map((ele, key) => {
                const e = JSON.parse(ele);
                return (
                  <div
                    className={
                      this.state.selected === key
                        ? this.props.classes.planSelected + " pricingcard"
                        : this.props.classes.plan + " pricingcard"
                    }
                    onClick={_ => this.setState({ selected: key })}
                    key={key}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={this.state.selected === key}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: this.props.classes.radio,
                          checked: this.props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <p className="pricingcard-name">
                        {e.ticket_name} <br />{" "}
                        <span className="pricingcard-days">
                          {e.ticket_status === "Paid"
                            ? "Standard"
                            : e.ticket_status}{" "}
                          Ticket
                        </span>
                      </p>
                    </div>
                    <div style={{ width: "50px" }}>
                      <p className="pricingcard-amount">
                        {e.ticket_price ? `$${e.ticket_price}` : "Free"}
                      </p>
                    </div>
                  </div>
                );
              })}

            {this.state.selected !== null && (
              <SummarySubTotalEvents
                plans={this.state.event.tickets}
                selected={this.state.selected}
              />
            )}
            {!eventEndDatePassed && this.state.event.isCartDisabled ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Register Here Now
                </button>
              </div>
            ) : (
              !eventEndDatePassed && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(52, 63, 100)`,
                      color: `white`,
                      fontWeight: `bold`,
                      border: 0,
                      marginTop: "40px",
                      cursor: "not-allowed"
                    }}
                  >
                    Add to Cart
                  </button>
                  <span>-------- OR --------</span>
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(211,52,49)`,
                      color: `white`,
                      marginTop: "10px",
                      fontWeight: `bold`,
                      border: 0,
                      cursor: "not-allowed"
                    }}
                  >
                    Register Here Now
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SpecificEvent);
