import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { Close as ClearIcon } from "@material-ui/icons";
import Button from "react-bootstrap/Button";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddBirthdayPartyModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ticket_name: "",
      ticket_price: 0,
      ticket_quantity: 10,
      ticket_status: this.props.ticketType,
      isTaxEnabled: false,
      taxInfo: [],
      selectedMembership: "",
      published_memberships: [],
      addOn: false,
      addOnItems: [],
      addOns: []
    };
  }

  async componentDidMount() {
    this.setGlobal({
      loading: true
    })

    const ep = `${process.env.REACT_APP_API}/partners/parties/addons`
    const result = await axios.get(ep);

    if (result.data.success) {
      this.setState({
        addOns: result.data.data
      });

      // const addon = result.data.data.find(addon => parseInt(addon.id) === parseInt(selectedAddon))
      // if (addon) {
      //   setAddonName(addon.name)
      //   setAddonPrice(addon.price)
      //   setAddonDescription(addon.description)
      // }

    } else {
      toast.error("Failed to load add-ons")
    }

    this.setGlobal({
      loading: false
    })
  }

  async getPublishedMemberships() {

  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    if (
      this.state.ticket_status !== "Free" &&
      this.state.ticket_price !== 0 &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }

    const obj = { ...this.state, id: this.global.party.tickets.length };

    if (!this.state.addOn) {
      delete obj.addOn;
      delete obj.addOnItems;
    }

    delete obj.addOns

    this.setGlobal({
      party: {
        ...this.global.party,
        ["tickets"]: [...this.global.party.tickets, obj]
      }
    });
    await this.dispatch.saveParty();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + " " + e.percentage + "%"
      }))
    ];
  };

  addOnOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    return [
      ...this.state.addOns.map((e, i) => ({
        text: e.name,
        value: e.price,
      }))
    ];
  };

  addOnsField = () => {
    return (
      <div
        className="col-12 d-flex"
        style={{
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "1px solid #cbcbcb",
            borderRadius: "5px",
            padding: "5px",
            width: "100%",
            marginTop: "10px",
            height: "180px",
            overflow: "auto"
          }}
        >
          {this.state.addOnItems.map(e => {
            return (
              <Button
                variant="primary"
                size="sm"
                style={{ margin: "3px 5px" }}
              >
                {`${e.text}: $${parseFloat(e.value).toFixed(2)}`}
                <ClearIcon
                  style={{
                    color: "white",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.setState({
                      addOnItems: this.state.addOnItems.filter(addon => addon.text !== e.text)
                    })
                  }}
                />
              </Button>
            );
          })}
        </div>
      </div>
    )
  }

  getBaseModalFields() {
    const ticketName = {
      type: STRING_INPUT,
      data: {
        name: "Package Name",
        required: true,
        handleChange: e => {
          this.setState({
            ticket_name: e.target.value
          });
        },
        value: this.state.ticket_name
      },
      validators: { validateSubmit: () => this.state.ticket_name !== "" }
    };

    const pricingTicket = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for Package",
        required: true,
        isDisabled: this.state.ticket_status === "Free",
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];
          console.log("Price 1: ", price);
          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }
          console.log("Price 2: ", price);
          this.setState({
            ticket_price: price.toString()
          });
        },
        value: this.state.ticket_price
      },
      validators: {
        validateSubmit: () =>
          this.state.ticket_price >= 0 && this.state.ticket_price !== ""
      }
    };

    const ticketQuantity = {
      type: STRING_INPUT,
      data: {
        name: "Ticket Quantity",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            ticket_quantity: e.target.value
          });
        },
        value: this.state.ticket_quantity
      }
    };

    const status = {
      type: TITLE,
      data: { name: `Type: ${this.state.ticket_status}` }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: true,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          if (!e.target.value) {
            this.setState({
              taxInfo: []
            });
            return;
          }

          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      },
      validators: { validateSubmit: () => !this.state.isTaxEnabled || this.state.taxInfo.length }
    };

    const selectMembership = {
      type: DROPDOWN,
      data: {
        name: "Select Membership",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedMembership: e.target.value
          });
        },
        choices: this.state.published_memberships
      },
      validators: { validateSubmit: () => this.state.selectedMembership !== "" }
    };

    const addsonFee = {
      type: SWITCH,
      data: {
        name: "Adds-On Fee",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.addOn,
        handleClick: e => this.setState({ addOn: e.target.checked })
      }
    };

    const fields = [];
    fields.push(ticketName);
    fields.push(pricingTicket);
    fields.push(ticketQuantity);

    fields.push(addsonFee);

    const addOnSelectionField = {
      type: DROPDOWN,
      data: {
        name: "Add-ons",
        required: true,
        placeholder: "Select Add-on",
        value: "Select Add-on",
        handleChange: e => {
          const name = e.target.options[e.target.selectedIndex].text

          if (e.target.selectedIndex === 0) {

          } else if (this.state.addOnItems.find(addon => addon.text === name)) {
            toast.error("You have already selected this add-on")
          } else {
            const addon = this.state.addOns.find(addon => addon.name === name)
            this.setState({
              addOnItems: [...this.state.addOnItems, {
                id: addon.id,
                text: addon.name,
                value: addon.price,
                description: addon.description
              }]
            })
          }
        },
        choices: this.addOnOptions()
      },
      validators: { validateSubmit: () => !this.state.isTaxEnabled || this.state.taxInfo.length }
    }

    const addOnOptionField = {
      type: GET_HTML,
      data: {
        gethtml: this.addOnsField
      },
      validators: {
        validateSubmit: () => {
          if (this.state.addOn) {
            return this.state.addOnItems.length > 0
          }
          return true;
        }
      }
    };

    if (this.state.addOn) {
      fields.push(addOnSelectionField);
      fields.push(addOnOptionField);
    }

    if (this.state.ticket_status === "Membership") {
      fields.push(selectMembership);
    }

    // if (this.state.addsonFee) {

    // }

    if (this.state.ticket_status !== "Free") {
      fields.push(enableTaxes);
    }
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    console.log("this.state.ticket_status", this.state.ticket_status);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddBirthdayPartyModal);
