import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus, background1 } from "../../assets";
import { toast } from "react-toastify";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
// import { toast } from "react-toastify";
import { format, addWeeks } from "date-fns";
import NotesModal from "../../components/modals/NotesModal";
import AddInstructorModal from "../../components/modals/AddInstructorModal";
import AddEnrollmentModal from "../../components/modals/AddEnrollmentModal";
// import { Delete } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/manageSingleProgram.css";
import "../../components/modals/MilestonesModal";
import MilestonesModal from "../../components/modals/MilestonesModal";
import WelcomePageModal from "../../components/modals/WelcomePageModal";
// import RoomsModal from "../../components/modals/RoomsModal";
import ApplicationViewModal from "../../components/modals/ApplicationViewModal";
import ParentMilestoneViewModal from "../../components/modals/ParentMilestoneViewModal";
import CancelProgramModal from "../../components/modals/CancelProgramModal";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import EndEnrollmentModal from "../../components/modals/EndEnrollmentModal";
import EditStartDateModal from "../../components/modals/EditStartDateModal";
import {
  Delete,
  SettingsBackupRestore as Restore,
  Create as Edit
} from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import ConfirmInactiveModal from "../../components/modals/ConfirmInactiveModal";
import NudgeInviteModal from "../../components/modals/NudgeInviteModal";
import EventShareLinkModal from "../../components/modals/EventShareLinkModal";
import EditBillingCycleModal from "../../components/modals/EditBillingCycleModal";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import "../../assets/css/componentSpecificCss/customNotesModal.css";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js";
import Editor from "../../components/partner/Editor";
class ManageSingleProgram extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openMilestoneModal: false,
      instructor: false,
      customers: {},
      openWelcomePageModal: false,
      showApplicationViewModal: false,
      showParentMilestoneViewModal: false,
      selectedProgram: null,
      type: "",
      displayCategory: "Programs",
      openCancelProgramModal: false,
      headings: {},
      openEndEnrollmentModal: false,
      openEditBillingCycleModal: false,
      showConfirmModal: false,
      toStatusStage: "Accepted_Paid",
      idToEdit: 0,
      showNudgeInviteModal: false,
      openShareLinkModal: false,
      showConfirmArchiveInviteModal: false,
      milestones: [],
      milestoneSubmissions: [],
      milestoneEnabled: false,
      editorState: EditorState.createEmpty(),
      openNewNotesModal: false
    };

    this.tabs = ["Enrollments", "Applications", "Inactive"];

    this.actions = [{ name: "Notes", action: null }];
  }

  // headings = () => {
  //   const obj =
  //   return obj
  // };

  async componentDidMount() {
    await this.fetchInitialData();
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Programs", to: "/programs/manage" },
        {
          label: this.state.data.program_name,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  // If the URL changes, refetch data and rerender componenet.
  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.fetchInitialData();
    }
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const param = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/programs/${param}`;
    const res = await axios.get(ep);
    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${param}`;
    const customers = await axios.get(customers_ep);
    console.log("customers...", customers);
    const ep2 = `${process.env.REACT_APP_API}/partners/invitations/program/${param}`;
    const res2 = await axios.get(ep2);
    console.log("this.state.data", this.state.data);
    const ep3 = `${process.env.REACT_APP_API}/partners/milestones/get/${param}`;
    const res3 = await axios.get(ep3);
    const ep4 = `${process.env.REACT_APP_API}/partners/welcome/partner_programs/${param}`;
    const res4 = await axios.get(ep4);

    if (res.data.success) {
      customers.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      this.setState({
        data: res.data.data,
        customers: {
          Enrollments: customers.data.data.filter(
            e =>
              e.status_stage === "Accepted_Paid" ||
              e.status_stage === "Accepted_Manual"
          ),
          Applications: customers.data.data.filter(
            e =>
              e.status_stage === "New_Pending" ||
              e.status_stage === "Accepted_Unpaid" ||
              e.status_stage === "Waitlisted_Unpaid" ||
              e.status_stage === "Waitlisted_Paid"
          ),
          Inactive: [
            ...customers.data.data.filter(
              e =>
                e.status_stage === "Rejected" ||
                e.status_stage === "Inactive" ||
                e.status_stage === "Cancelled" ||
                e.status_stage === "Cancelled_Unpaid"
            ),
            ...res2.data.data.filter(e => !e.isArchived)
          ]
        }
      });
    }

    if (res3.data.success) {
      this.setState({
        milestones: res3.data.data.sort((a, b) => {
          return a.display_order - b.display_order;
        })
      });
    }

    if (res4.data.success) {
      this.setState({
        milestoneEnabled: res4?.data?.data?.data?.milestones
      });
    }

    // Setting the headings as a state object
    this.setState({
      headings: {
        Enrollments: [
          {
            id: "name",
            label: "Name",
            customCell: (r, i) => {
              return <td key={i}>{r.displayName}</td>;
            }
          },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          {
            id: "ptype",
            label: "Program Plan",
            customCell: (r, i) => {
              if (r.plan_id === null) {
                return <td key={i}>Auto Register</td>;
              }
              const plan = JSON.parse(
                this.state.data.plans.filter(
                  e => JSON.parse(e).id == r.plan_id
                )[0]
              );
              const objConv = {
                week: "Weekly",
                "bi-week": "Bi-Weekly",
                month: "Monthly"
              };
              return (
                <td key={i}>
                  {plan.type === "Semester"
                    ? r.drop_in_dates
                      ? `Semester Drop-in - $${parseFloat(
                          (plan.total_price / plan.dayCount) *
                            (1 + parseInt(plan.dropin_interest) / 100)
                        ).toFixed(0)}`
                      : plan.installments && r.isSemesterInstallment
                      ? `Semester - Inst - $${parseFloat(
                          (plan.total_price / plan.installments_breakdown) *
                            (1 + parseInt(plan.installments_interest) / 100)
                        ).toFixed(0)} - ${plan.installments_plan}`
                      : plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price}`
                      : `Semester - $${plan.total_price}`
                    : plan.type === "Free"
                    ? plan.description && plan.description !== ""
                      ? `Free - ${plan.description}`
                      : "Free"
                    : plan.type === "Free Trial"
                    ? plan.description && plan.description !== ""
                      ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
                      : `Free Trial (${plan.trialPeriod} classes)`
                    : plan.type === "Single Sessions"
                    ? plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price} x (${r.userSelectedQuantity})`
                      : `Single Sessions - $${plan.total_price} x (${r.userSelectedQuantity})`
                    : plan.type === "Drop-In"
                    ? plan.description && plan.description !== ""
                      ? `${plan.drescription} - $${plan.total_price} x ${r.drop_in_dates.length}`
                      : `Drop-In - $${plan.total_price} x ${r.drop_in_dates.length}`
                    : `Recurring - $${plan.tuition_rate} / ${
                        objConv[plan.billing_cycle]
                      }, \n ${
                        plan.isReplaceTimeEnabled
                          ? `${plan.description}`
                          : `${plan.timing[0]} to ${plan.timing[1]}`
                      }`}
                </td>
              );
            }
          },
          {
            id: "start",
            label: "Dates",
            customCell: (r, i) => {
              if (r.plan_id === null) {
                return <td key={i}></td>;
              }
              const plan = JSON.parse(
                this.state.data.plans.filter(
                  e => JSON.parse(e).id == r.plan_id
                )[0]
              );

              return (
                <td key={i}>
                  {r.startDate &&
                    moment(
                      new Date(
                        r.startDate.replace(/-/g, "/").replace(/T.+/, "")
                      )
                    ).format("MMM DD, YYYY")}
                  {r.drop_in_dates &&
                    `${r.drop_in_dates
                      .map(e => format(new Date(e), "LLLL dd, yyyy"))
                      .join(", ")}`}
                </td>
              );
            }
          },
          {
            id: "end",
            label: "End Date",
            customCell: (r, i) => <td key={i}>---</td>
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              // const hasSubmitted = this.state.milestoneSubmissions.some(
              //   submission => submission.parentId === r.dsUserId
              // );
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  {this.state.milestones.length > 0 &&
                    this.state.milestoneEnabled && (
                      <button
                        style={{ marginRight: "10px" }}
                        onClick={_ => {
                          this.setState({
                            selectedProgram: r,
                            showParentMilestoneViewModal: true
                          });
                        }}
                      >
                        View Milestones
                      </button>
                    )}
                  <button
                    style={{ marginRight: "10px" }}
                    // onClick={_ =>
                    //   this.props.history.push(`/partners/application/${r.id}`)
                    // }
                    onClick={_ => {
                      // this.props.history.push(`/partners/application/${r.id}`)
                      let appStatus;
                      if (
                        r.status_stage === "Accepted_Paid" ||
                        r.status_stage === "Accepted_Manual"
                      ) {
                        appStatus = "Enrolled";
                      }

                      this.setState({
                        selectedProgram: r,
                        showApplicationViewModal: true,
                        type: appStatus
                      });
                    }}
                  >
                    View Details
                  </button>
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={_ => this.viewNotes(r, "programs_customers")}
                  >
                    Notes
                  </button>
                </td>
              );
            }
          }
        ],
        Applications: [
          {
            id: "name",
            label: "Name",
            customCell: (r, i) => {
              return <td key={i}>{r.displayName}</td>;
            }
          },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          {
            id: "ptype",
            label: "Program Plan",
            customCell: (r, i) => {
              if (r.plan_id === null) {
                return <td key={i}></td>;
              }
              const plan = JSON.parse(
                this.state.data.plans.filter(
                  e => JSON.parse(e).id == r.plan_id
                )?.[0] || null
              );
              const objConv = {
                week: "Weekly",
                "bi-week": "Bi-Weekly",
                month: "Monthly"
              };
              if (!plan) return <td>null</td>; //mostly useful in dev, due to erroneous plan data!
              return (
                <td key={i}>
                  {plan.type === "Semester"
                    ? r.drop_in_dates
                      ? `Semester Drop-in - $${parseFloat(
                          (plan.total_price / plan.dayCount) *
                            (1 + parseInt(plan.dropin_interest) / 100)
                        ).toFixed(0)}`
                      : plan.installments && r.isSemesterInstallment
                      ? `Semester - Inst - $${parseFloat(
                          (plan.total_price / plan.installments_breakdown) *
                            (1 + parseInt(plan.installments_interest) / 100)
                        ).toFixed(0)} - ${plan.installments_plan}`
                      : plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price}`
                      : `Semester - $${plan.total_price}`
                    : plan.type === "Free"
                    ? plan.description && plan.description !== ""
                      ? `Free - ${plan.description}`
                      : "Free"
                    : plan.type === "Free Trial"
                    ? plan.description && plan.description !== ""
                      ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
                      : `Free Trial (${plan.trialPeriod} classes)`
                    : plan.type === "Drop-In"
                    ? plan.description && plan.description !== ""
                        ? `Drop-In - ${plan.description}`
                        : "Drop-In"
                    : plan.type === "Single Sessions"
                    ? plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price} x (${r.userSelectedQuantity})`
                      : `Single Sessions - $${plan.total_price} x (${r.userSelectedQuantity})`
                    : `Recurring - $${plan.tuition_rate} / ${
                        objConv[plan.billing_cycle]
                      }, \n ${
                        plan.isReplaceTimeEnabled
                          ? `${plan.description}`
                          : `${plan.timing[0]} to ${plan.timing[1]}`
                      }`}
                </td>
              );
            }
          },
          // { Feb21st Moh and Aslam decided to comment this block
          //   id: "endDate",
          //   label: "Duration",
          //   customCell: (r, i) =><td key={i}>{
          //             format(new Date(r.program_start), "LL/dd/yy")} -
          //             {format(
          //               addWeeks(
          //                 new Date(r.program_start),
          //                 r.number_of_weeks
          //               ),
          //               "LL/dd/yy"
          //             )
          //           }</td>
          // },
          {
            id: "status_stage",
            label: "Status",
            customCell: (r, i) => (
              <td key={i}>
                {r.status_stage === "Accepted_Unpaid"
                  ? this.state.data.application_required
                    ? "Accepted_Unpaid"
                    : "Direct_Unpaid"
                  : r.status_stage}
              </td>
            )
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={_ => {
                      // this.props.history.push(`/partners/application/${r.id}`)
                      let appStatus;
                      if (r.status_stage === "New_Pending") {
                        appStatus = "New";
                      }
                      if (r.status_stage === "Accepted_Unpaid") {
                        appStatus = "Accepted";
                      }
                      if (
                        r.status_stage === "Waitlisted_Unpaid" ||
                        r.status_stage === "Waitlisted_Paid"
                      ) {
                        appStatus = "Waitlisted";
                      }
                      if (r.status_stage === "Rejected") {
                        appStatus = "Rejected";
                      }

                      this.setState({
                        selectedProgram: r,
                        showApplicationViewModal: true,
                        type: appStatus
                      });
                    }}
                  >
                    View Details
                  </button>
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={_ => this.viewNotes(r, "programs_customers")}
                  >
                    Notes
                  </button>
                </td>
              );
            }
          }
        ],
        Inactive: [
          {
            id: "name",
            label: "Name",
            customCell: (r, i) => {
              return <td key={i}>{r.displayName}</td>;
            }
          },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          {
            id: "ptype",
            label: "Program Plan",
            customCell: (r, i) => {
              if (r.plan_id === null) {
                return <td key={i}>Auto Register</td>;
              }
              const plan = JSON.parse(
                this.state.data.plans.filter(
                  e => JSON.parse(e).id == r.plan_id
                )[0]
              );
              const objConv = {
                week: "Weekly",
                "bi-week": "Bi-Weekly",
                month: "Monthly"
              };
              const hasPromo = !r.isPromotion ? false : true;

              let isPromoPercentage = false;
              if (hasPromo) {
                isPromoPercentage =
                  r.promo?.discount_type === "Percentage" ? true : false;
              }
              return (
                <td key={i}>
                  {plan.type === "Semester"
                    ? r.drop_in_dates
                      ? `Semester Drop-in - $${parseFloat(
                          (plan.total_price / plan.dayCount) *
                            (1 + parseInt(plan.dropin_interest) / 100)
                        ).toFixed(0)}`
                      : plan.installments
                      ? `Semester - Inst - $${parseFloat(
                          (plan.total_price / plan.installments_breakdown) *
                            (1 + parseInt(plan.installments_interest) / 100)
                        ).toFixed(0)} - ${plan.installments_plan}`
                      : plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price}`
                      : `Semester - $${plan.total_price}`
                    : plan.type === "Free"
                    ? plan.description && plan.description !== ""
                      ? `Free - ${plan.description}`
                      : "Free"
                    : plan.type === "Free Trial"
                    ? plan.description && plan.description !== ""
                      ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
                      : `Free Trial - (${plan.trialPeriod} classes)`
                    : plan.type === "Single Sessions"
                    ? plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price} x (${
                          r.userSelectedQuantity || r.singleSessionQuantity
                        })`
                      : `Single Sessions - $${plan.total_price} x (${
                          r.userSelectedQuantity || r.singleSessionQuantity
                        })`
                    : plan.type === "Drop-In"
                    ? plan.description && plan.description !== ""
                      ? `${plan.description} - $${plan.total_price} ${r.status_stage ? `x ${r.drop_in_dates?.length}` : "/ day"}`
                      : `Drop-In - $${plan.total_price} ${r.status_stage ? `x ${r.drop_in_dates?.length}` : "/ day"}`
                    : `Recurring - $${plan.tuition_rate} / ${
                        objConv[plan.billing_cycle]
                      }, \n ${
                        plan.isReplaceTimeEnabled
                          ? `${plan.description}`
                          : `${plan.timing[0]} to ${plan.timing[1]}`
                      }`}
                  {hasPromo
                    ? `${
                        isPromoPercentage
                          ? `, Promotion: ${r.promo.discount_percent}%`
                          : `, Promotion: $${r.promo.discount_fixed}`
                      }`
                    : ""}
                </td>
              );
            }
          },
          // { Feb21st Moh and Aslam decided to comment it out
          //   id: "endDate",
          //   label: "Duration",
          //   customCell: (r, i) =><td key={i}>{
          //     format(new Date(r.program_start), "LL/dd/yy")} -
          //     {format(
          //       addWeeks(
          //         new Date(r.program_start),
          //         r.number_of_weeks
          //       ),
          //       "LL/dd/yy"
          //     )
          //   }</td>
          // },
          {
            id: "endDate",
            label: "End Date",
            customCell: (r, i) => (
              <td key={i}>
                {r.endDate ? format(new Date(r.endDate), "LLLL dd, yyyy") : ""}
              </td>
            )
          },
          {
            id: "status_stage",
            label: "Status",
            customCell: (r, i) => (
              <td key={i}>{r.status_stage ? r.status_stage : "Invited"}</td>
            )
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  {r.came_from ? (
                    <button
                      style={{ marginRight: "15px" }}
                      onClick={_ => {
                        this.setState({
                          selectedProgram: r,
                          showNudgeInviteModal: true
                        });
                      }}
                    >
                      Nudge
                    </button>
                  ) : (
                    <>
                      <button
                        style={{ marginRight: "15px" }}
                        onClick={_ => {
                          // this.props.history.push(`/partners/application/${r.id}`)
                          let appStatus;
                          if (r.status_stage === "New_Pending") {
                            appStatus = "New";
                          }
                          if (r.status_stage === "Accepted_Unpaid") {
                            appStatus = "Accepted";
                          }
                          if (
                            r.status_stage === "Waitlisted_Unpaid" ||
                            r.status_stage === "Waitlisted_Paid"
                          ) {
                            appStatus = "Waitlisted";
                          }
                          if (r.status_stage === "Rejected") {
                            appStatus = "Rejected";
                          }

                          this.setState({
                            selectedProgram: r,
                            showApplicationViewModal: true,
                            type: appStatus
                          });
                        }}
                      >
                        View Details
                      </button>
                      <button
                        style={{ marginRight: "0px" }}
                        onClick={_ => this.viewNotes(r, "programs_customers")}
                      >
                        Notes
                      </button>
                    </>
                  )}
                </td>
              );
            }
          },

          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              if (r.plan_id === null) {
                return <td key={i}></td>;
              }
              const plan = JSON.parse(
                this.state.data.plans.filter(
                  e => JSON.parse(e).id == r.plan_id
                )[0]
              );
              return (
                <td key={`${i}-btn`} style={{ textAlign: "center" }}>
                  {r.came_from ? (
                    <>
                      {!this.canSubUserEdit() && (
                        <div style={{ marginRight: "0px" }}>
                          <Delete
                            onClick={() => {
                              this.setState({
                                showConfirmArchiveInviteModal: true,
                                idToEdit: r.id
                              });
                            }}
                            style={{ color: "#BBB", cursor: "pointer" }}
                            data-for="archive"
                            data-tip
                          />
                          <ReactTooltip
                            id="archive"
                            place="top"
                            effect="solid"
                            type="info"
                          >
                            Archive Invite
                          </ReactTooltip>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {!this.canSubUserEdit() &&
                        (plan.type === "Semester" ||
                          plan.type === "Free" ||
                          plan.type === "Single Sessions") && (
                          <div style={{ marginRight: "0px" }}>
                            <Restore
                              onClick={() => {
                                this.setState({
                                  showConfirmModal: true,
                                  idToEdit: r.id,
                                  toStatusStage: r.status_stage
                                });
                              }}
                              style={{ color: "#BBB" }}
                              data-for="restore"
                              data-tip
                            />
                            <ReactTooltip
                              id="restore"
                              place="top"
                              effect="solid"
                              type="info"
                            >
                              Restore Module
                            </ReactTooltip>
                          </div>
                        )}
                    </>
                  )}
                </td>
              );
            }
          }
        ]
      }
    }); // Ending of this.setState
    this.setGlobal({ loading: false });
  };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  viewNotes = (row, table) => {
    if (table === "partner_programs") {
      this.setState({
        notesModalShown: true,
        modalData: row,
        table: table
      });
    } else {
      let contentState = ContentState.createFromText("");

      if (row.notes) {
        const blocksFromHTML = convertFromHTML(row.notes);
        if (blocksFromHTML && blocksFromHTML.contentBlocks) {
          contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
        }
      }

      this.setState({
        openNewNotesModal: true,
        editorState: row?.custom_notes
          ? EditorState.createWithContent(convertFromRaw(row?.custom_notes))
          : row.notes
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
        modalData: row,
        table: table
      });
    }
  };

  inactiveEnrolled = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/customers/restore-inactive/${this.state.idToEdit}`;

    const results = await axios.post(ep, {
      toStatusStage: this.state.toStatusStage
    });

    console.log("results", results);
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  archiveInvite = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/invitations/archive/${this.state.idToEdit}`;
    const results = await axios.post(ep);
    console.log("results", results);
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   *  returns boolean
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Manage.Programs.edit;
    }
  };

  pauseEnrollment = async (isStripe, id, resume) => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/orders/toggle-pause`;
    const res = await axios.post(ep, { id: id, is_stripe: isStripe });
    if (res.data.success) {
      toast.success(`Successfully ${resume ? "resumed" : "paused"} enrollment`);
    } else {
      toast.error("Please wait until the next invoice has been generated!");
    }
    this.setState({ showApplicationViewModal: false });
    await this.fetchInitialData();
  };

  getFilteredCustomers() {
    // Create a deep copy of the customers object
    const customersArr = JSON.parse(JSON.stringify(this.state.customers));

    if (customersArr["Applications"]) {
      customersArr["Applications"] = customersArr["Applications"].filter(
        customer => {
          const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
          const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
          const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
          return customerName.includes(filter) || (childName && childName.includes(filter));
        }
      );
    }

    if (customersArr["Enrollments"]) {
      customersArr["Enrollments"] = customersArr["Enrollments"].filter(
        customer => {
          const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
          const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
          const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
          return customerName.includes(filter) || (childName && childName.includes(filter));
        }
      );
    }

    if (customersArr["Inactive"]) {
      customersArr["Inactive"] = customersArr["Inactive"].filter(customer => {
        const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
        const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return customerName.includes(filter) || (childName && childName.includes(filter));
      });
    }

    return customersArr;
  }

  async saveCustomNotes() {
    const endPnt = `${process.env.REACT_APP_API}/partners/notes/custom-notes`;
    const res = await axios.post(endPnt, {
      id: this.state.modalData?.id,
      custom_notes: convertToRaw(this.state.editorState?.getCurrentContent()),
      table: "programs_customers"
    });

    if (res.data.success) {
      this.fetchInitialData();
      toast.success("Notes successfully updated");
      this.setState({
        openNewNotesModal: false,
        editorState: EditorState.createEmpty()
      });
    }
  }

  render() {
    console.log("this.state....", this.state);
    console.log("this.props", this.props);
    return (
      <div className="admin">
        <div>
          <Modal
            size="lg"
            isOpen={this.state.openNewNotesModal}
            toggle={() =>
              this.setState({
                openNewNotesModal: !this.state.openNewNotesModal
              })
            }
            contentClassName="notes-custom-modal-content"
          >
            <div
              className="modal-content"
              style={{ borderRadius: "30px 30px 0px 0px" }}
            >
              <div className="modal-header">
                <h5 className="modal-title staff-modal-title">Notes</h5>
                <button
                  className="close"
                  style={{ paddingTop: "16px" }}
                  onClick={() => this.setState({ openNewNotesModal: false })}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
            <ModalBody>
              <form>
                <Row>
                  <Col lg={12}>
                    <div>
                      <label htmlFor="notes">Your Notes</label>
                      <Editor
                        editorState={this.state.editorState}
                        setEditorState={editor => {
                          this.setState({ editorState: editor });
                        }}
                        isNotes={true}
                      />
                    </div>
                  </Col>
                </Row>
              </form>
              <div style={{ textAlign: "center" }}>
                <button
                  className="staff-schedule-save-btn"
                  onClick={() => this.saveCustomNotes()}
                >
                  Save
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>

        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
            onRefresh={() => this.fetchInitialData()}
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmInactiveModal
            onSubmit={() => {
              this.inactiveEnrolled();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toStatusStage={this.state.toStatusStage}
          />
        )}
        {this.state.showConfirmArchiveInviteModal && (
          <ConfirmInactiveModal
            onSubmit={() => {
              this.archiveInvite();
            }}
            onClose={_ =>
              this.setState({ showConfirmArchiveInviteModal: false })
            }
            toArchive={true}
            modalBody={"Are you sure you would like to archive this invite?"}
          />
        )}
        {this.state.instructor && (
          <AddInstructorModal
            open={this.state.instructor}
            program={this.state.modalProgram}
            onClose={() =>
              this.setState({
                instructor: false,
                modalProgram: null
              })
            }
            type="Program"
          />
        )}
        {this.state.addEnrollment && (
          <AddEnrollmentModal
            open={this.state.addEnrollment}
            program={this.state.modalProgram}
            onClose={() => {
              this.setState({
                addEnrollment: false,
                modalProgram: null
              });
              this.fetchInitialData();
            }}
            table={"partner_programs"}
            promotion_type="program"
          />
        )}
        {this.state.openMilestoneModal && (
          <MilestonesModal
            onClose={_ => this.setState({ openMilestoneModal: false })}
            history={this.props.history}
            program_id={this.props.match.params.id}
            type="Program"
          />
        )}

        {this.state.openWelcomePageModal && (
          <WelcomePageModal
            onClose={_ => this.setState({ openWelcomePageModal: false })}
            onRefresh={() => this.fetchInitialData()}
            data={this.state.data}
            setGlobal={this.setGlobal}
            global={this.global}
            table={"partner_programs"}
          />
        )}

        {this.state.openCancelProgramModal && (
          <CancelProgramModal
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openCancelProgramModal: false });
            }}
            data={this.state.data}
            // custommers={this.state.customers}
            // success={e => this.fetchInitialData()}
            // setGlobal={this.setGlobal}
            // global={this.global}
            table={"partner_programs"}
          />
        )}

        {this.state.showParentMilestoneViewModal && (
          <ParentMilestoneViewModal
            program={this.state.selectedProgram}
            onClose={() =>
              this.setState({ showParentMilestoneViewModal: false })
            }
            milestone={this.state.milestones}
            type={"Program"}
          />
        )}
        {this.state.showApplicationViewModal && (
          <ApplicationViewModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({ showApplicationViewModal: false });
            }}
            type={this.state.type}
            displayCategory={this.state.displayCategory}
            isApplication={this.state.data.application_required}
            endEnrollment={_ => {
              this.setState({
                openEndEnrollmentModal: true,
                showApplicationViewModal: false
              });
            }}
            canSubUserEdit={this.canSubUserEdit()}
            pauseEnrollment={(isStripe, subscription) => {
              this.pauseEnrollment(isStripe, subscription);
            }}
            refresh={this.fetchInitialData}
            openEditStart={() =>
              this.setState({ openEditStartDateModal: true })
            }
            openEditBillingCycle={() =>
              this.setState({ openEditBillingCycleModal: true })
            }
          />
        )}

        {this.state.openEditStartDateModal && (
          <EditStartDateModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({
                openEditStartDateModal: false,
                showApplicationViewModal: false
              });
              this.fetchInitialData();
            }}
          />
        )}

        {this.state.openEditBillingCycleModal && (
          <EditBillingCycleModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({
                openEditBillingCycleModal: false,
                showApplicationViewModal: false
              });
              this.fetchInitialData();
            }}
          />
        )}

        {this.state.openEndEnrollmentModal && (
          <EndEnrollmentModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openEndEnrollmentModal: false });
            }}
            listingType={"Program"}
          />
        )}

        {this.state.showNudgeInviteModal && (
          <NudgeInviteModal
            invite={this.state.selectedProgram}
            program_table={"partner_programs"}
            fetchData={_ => this.fetchInitialData()}
            onClose={_ => this.setState({ showNudgeInviteModal: false })}
          />
        )}
        {this.state.openShareLinkModal && (
          <EventShareLinkModal
            onClose={_ => {
              this.setState({ openShareLinkModal: false });
            }}
            programId={this.state.data.id}
            type={"Program"}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>{this.state.data.program_name}</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp enrollment-btn"
                  style={{ marginLeft: "20px" }}
                >
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.setState({
                        addEnrollment: true,
                        modalProgram: this.state.data
                      })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="display-container">
              <div className="left-container">
                <div className="image-container">
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.program_photo}`}
                    alt="Program"
                    className="image"
                    wrapperClassName="image"
                    effect="opacity"
                  />
                </div>
                <p className="program-name">{this.state.data.program_name}</p>
                <p className="program-category">
                  {this.state.data.program_category}
                </p>
                <div className="address-container">
                  <h3 className="address-title">
                    {this.state.data.address1} {this.state.data.address2}{" "}
                    {this.state.data.city} {this.state.data.province}
                  </h3>
                </div>
                <div className="options-container">
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.state.data.addChild
                        ? this.props.history.push(
                            this.state.data.number_of_weeks > 0 ||
                              this.state.data.manual_invoices ||
                              this.state.data.isRecurring
                              ? `/partners/manage/simple-attendance/${this.state.data.id}?Program`
                              : `/partners/manage/attendance/${this.state.data.id}?Program`
                          )
                        : this.props.history.push(
                            this.state.data.number_of_weeks > 0 ||
                              this.state.data.manual_invoices ||
                              this.state.data.isRecurring
                              ? `/partners/manage/simple-attendance/user/${this.state.data.id}?Program`
                              : `/partners/manage/attendance/user/${this.state.data.id}?Program`
                          );
                    }}
                  >
                    <h3 className="option-name">Manage Attendance</h3>
                    <i className="far fa-calendar-check option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.viewNotes(this.state.data, "partner_programs");
                    }}
                  >
                    <h3 className="option-name">Program Notes</h3>
                    <i className="fas fa-pencil-alt option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({
                            instructor: true,
                            modalProgram: this.state.data
                          });
                    }}
                  >
                    <h3 className="option-name">Instructors</h3>
                    <i className="fas fa-info option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.props.history.push({
                            pathname: `/partners/send-message`,
                            search: `?type=Programs&id=${this.props.match.params.id}`,
                            query: {
                              type: "Programs",
                              id: `${this.props.match.params.id}`
                            }
                          });
                    }}
                  >
                    <h3 className="option-name">Message Participants</h3>
                    <i className="fas fa-bullhorn option-img"></i>
                  </div>
                  {/* <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                  >
                    <h3 className="option-name">Pause Programs</h3>
                    <i className="far fa-pause-circle option-img"></i>  Commented out on March 16 by Moh and Josh
                  </div> */}
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openCancelProgramModal: true });
                    }}
                  >
                    <h3 className="option-name">Cancel Program</h3>
                    <i className="fas fa-times-circle option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openWelcomePageModal: true });
                    }}
                  >
                    <h3 className="option-name">Welcome Page</h3>
                    <i className="far fa-edit option-img"></i>
                  </div>
                  {this.state.data.rooms && (
                    <div
                      className="option-list"
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer"
                      }}
                      onClick={_ => {
                        return this.canSubUserEdit()
                          ? ""
                          : this.props.history.push(
                              `/partners/rooms/program/${this.props.match.params.id}`
                            );
                      }}
                    >
                      <h3 className="option-name">Rooms</h3>
                      <i className="fas fa-user-friends"></i>
                    </div>
                  )}
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openMilestoneModal: true });
                    }}
                  >
                    <h3 className="option-name">Milestones</h3>
                    <i className="fas fa-book-reader option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openShareLinkModal: true });
                    }}
                  >
                    <h3 className="option-name">Shareable Link</h3>
                    <i className="fas fa-share"></i>
                  </div>
                </div>
              </div>
              <div className="tablecont">
                <div className="table-responsive">
                  <div
                    style={{
                      display: "inline-flex",
                      flex: "1 1",
                      border: "none",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%"
                    }}
                  >
                    <i
                      className="fas fa-search"
                      style={{ padding: "8px", background: "white" }}
                    ></i>
                    <input
                      type="text"
                      placeholder="Search by name"
                      style={{
                        border: "none",
                        fontSize: "13px",
                        padding: "5px",
                        outline: 0,
                        background: "white",
                        flex: 1
                      }}
                      value={this.state.filter}
                      onChange={e => {
                        this.setState({ filter: e.target.value });
                      }}
                    />
                  </div>
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.state.headings}
                    data={this.getFilteredCustomers()}
                    actions={this.actions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageSingleProgram;
