import React from "reactn";
import FranchiserEarnings from "./financialsPage/FranchiserEarnings/FranchiserEarnings";
import FranchiserOrders from "./financialsPage/FranchiserOrders/FranchiserOrders";
import FranchiserRecurringPayments from "./financialsPage/FranchiserRecurringPayments/FranchiserRecurringPayments";
import axios from "axios";
import { ImageActiveA } from "../../assets";
import "./financialsPage/headingStyle.css";

class FranchiseFinancials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currTab: "Earnings Summary",
      organizations: [],
      selectedCompany: ""
    };
    this.setGlobal({
      franchiseUser: {
        ...this.global.franchiseUser,
        selectedId: 0
      }
    })
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/franchise/dashboard/${this.global.franchiseUser.id}`;
    const res = await axios.get(ep);
    if(res.data.success) {
      const partners = res.data.data;
      this.setState({
        organizations: partners.map((partner) => {
          return {
            company: partner.company,
            partnerId: partner.id,
          }
        }),
      });
    }
  }

  render() {
    return (
      <>
        <div className="row cont">
          <div className="col-md-6">
            <h1>Franchise Financials</h1>
            <div className="dropdown-wrapper">
              <label htmlFor="client_name" className="dropdown-label">
                Organization
              </label>
              <select
                name="organization"
                id="organization"
                type="text"
                className="form-control filter-input org-dropdown"
                value={this.global.franchiseUser.selectedId}
                onChange={e => {
                    this.setGlobal({
                      franchiseUser: {
                        ...this.global.franchiseUser, 
                        selectedId: e.target.value,
                      }
                    });
                  }
                }
              >
                <option selected hidden disabled>
                  Please Select
                </option>
                <option value={-1} selected>
                  All
                </option>
                {this.state.organizations
                  .sort((a, b) => a.company.localeCompare(b.company))
                  .map(e => {
                    return (
                      <option key={e.id} value={e.partnerId}>
                        {e.company}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tablecont">
                <ul>
                    <li key={"Earnings Summary"} className={"Earnings Summary" === this.state.currTab ? "active" : ""}>
                      <button onClick={() => {
                        this.setState({currTab: "Earnings Summary"});
                      }}>
                        <img src={ImageActiveA} className="foractive" alt="" />
                        Earnings Summary
                      </button>
                    </li>
                    <li key={"Paid Orders"} className={"Paid Orders" === this.state.currTab ? "active" : ""}>
                      <button onClick={() => {
                        this.setState({currTab: "Paid Orders"});
                      }}>
                        <img src={ImageActiveA} className="foractive" alt="" />
                        Paid Orders
                      </button>
                    </li>
                    <li key={"Recurring Payments"} className={"Recurring Payments" === this.state.currTab ? "active" : ""}>
                      <button onClick={() => {
                        this.setState({currTab: "Recurring Payments"});
                      }}>
                        <img src={ImageActiveA} className="foractive" alt="" />
                        Recurring Payments
                      </button>
                    </li>
                </ul>
              </div>
              <div className="tablecont overflow-x-scroll" style={{boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
                {this.state.currTab === "Earnings Summary" && <FranchiserEarnings/>}
                {this.state.currTab === "Paid Orders" && <FranchiserOrders/>}
                {this.state.currTab === "Recurring Payments" && <FranchiserRecurringPayments/>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default FranchiseFinancials;