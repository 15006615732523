import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MemberShip1, MemberShip2, MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import CreateMembershipModal from "../../components/modals/CreateMembershipModal";
import PreviewMembershipListingsModal from "../../components/modals/PreviewMembershipListingsModal";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { withStyles } from "@material-ui/styles";
import ViewArchivedMembershipDetailsModal from "../../components/modals/ViewArchivedMembershipDetailsModal";

const styles = {
  filter: {
    height: "165px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  filterArchived: {
    height: "130px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  checkbox: {
    minWidth: "32px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
};

class MembershipList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      data: { Memberships: [] },
      allData: [],
      membershipCount: { Memberships: [], Archive: [] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openCreateMembershipModal: false,
      openPreviewMembershipListingsModal: false,
      showConfirmModal: false,
      showConfirmHideModal: false,
      toArchive: true,
      toHide: true,
      idToEdit: 0,
      cancelAllApplications: false,
      filterListChecked: [],
      isPreview: true,
      isName1: false,
      isDate1: false,
      isDate2: false,
      currTab: 0,
      previewOrder: [],
      previewUpdated: false,
      page: -1
    };

    this.tabs = ["Memberships", "Archive"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Memberships", to: "/memberships/list" }
      ]
    });

    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);
    this.filterListLabels = [
      "Preview Order",
      "Name (A to Z)",
      "Created Date (new to old)",
      "Update Date (new to old)"
    ];
  }

  headings = () => {
    return {
      Memberships: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", maxWidth: "130px" }}>
              <LazyLoadImage
                src={
                  r.membership_image === 2
                    ? `${process.env.REACT_APP_DS_FILES_S3}/${r.membership_image_url}`
                    : r.membership_image === 0
                    ? MemberShip1
                    : MemberShip2
                }
                style={{ maxWidth: "130px", borderRadius: "5px" }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "membership_name", label: "Name" },
        {
          id: "price_per_term",
          label: "Price",
          customCell: (r, i) => <td key={i}>${r.price_per_term} / Term</td>
        },
        {
          id: "membership_term",
          label: "Term",
          customCell: (r, i) => <td key={i}>{r.membership_term} Month(s)</td>
        },
        {
          id: "max_amount",
          label: "Capacity",
          customCell: (r, i) => (
            <td key={i}>{r.max_amount ? r.max_amount : "Unlimited"}</td>
          )
        },
        {
          id: "status",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <Switch
                  checked={r.published}
                  size="small"
                  disabled={
                    this.canSubUserEdit() ||
                    !r.membership_name ||
                    !r.membership_type ||
                    r.prices.filter(plan => {
                      let ret = false;
                      const { active, archived } = JSON.parse(plan);
                      if ((active === undefined || active) && !archived)
                        ret = true;
                      return ret;
                    }).length === 0 ||
                    r.formId === null
                  }
                  onChange={_ => {
                    this.updatePublishStatus(r);
                  }}
                  color="primary"
                />
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/memberships/setup/${r.id}`
                      )
                    }
                  >
                    Edit
                  </button>
                  <LinkIcon
                    style={{
                      transform: "rotate(130deg)",
                      marginRight: "10px",
                      color: "darkgrey"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        selectedIframe: r.id,

                        type: "membership"
                      })
                    }
                    className="hovermyX"
                  />
                  {!this.canSubUserEdit() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Archive Membership
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", maxWidth: "130px" }}>
              <LazyLoadImage
                src={
                  r.membership_image === 2
                    ? `${process.env.REACT_APP_DS_FILES_S3}/${r.membership_image_url}`
                    : r.membership_image === 0
                    ? MemberShip1
                    : MemberShip2
                }
                style={{ maxWidth: "130px" }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "membership_name", label: "Name" },
        {
          id: "price_per_term",
          label: "Price",
          customCell: (r, i) => <td key={i}>${r.price_per_term} / Term</td>
        },
        {
          id: "membership_term",
          label: "Term",
          customCell: (r, i) => <td key={i}>{r.membership_term} Month(s)</td>
        },
        {
          id: "max_amount",
          label: "Capacity"
        },
        // {
        //   id: "published",
        //   label: "Status",
        //   customCell: (r, i) => (
        //     <td key={i}>{r.published ? "Open" : "Pending"}</td>
        //   )
        // },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  {!this.canSubUserEdit() && this.allowRestoreModalButton() && (
                    <>
                      <Restore
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: false
                          });
                        }}
                        style={{ color: "#BBB" }}
                        data-for="restore"
                        data-tip
                      />
                      <ReactTooltip
                        id="restore"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Restore Membership
                      </ReactTooltip>
                    </>
                  )}
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={_ => this.setState({ 
                      openViewArchivedModal: true,
                      idToEdit: r.id
                    })
                    }
                  >
                    View
                  </button>
                  {!this.canSubUserEdit() && this.allowRestoreModalButton() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmHideModal: true,
                            idToEdit: r.id,
                            toArchive: true,
                            toHide: true,
                            cancelAllApplications: !!this.state.customers.find(
                              customer => {
                                return (
                                  customer.membershipId === r.id &&
                                  (customer.status_stage ===
                                    "Accepted_Unpaid" ||
                                    customer.status_stage === "New_Pending" ||
                                    customer.status_stage ===
                                      "Accepted_Manual" ||
                                    customer.status_stage ===
                                      "Waitlisted_Unpaid")
                                );
                              }
                            )
                          });
                        }}
                        style={{ color: "#BBB" }}
                        data-for="hide"
                        data-tip
                      />
                      <ReactTooltip
                        id="hide"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete Membership
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ]
    };
  };

  updatePublishStatus = async r => {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/publish`;
    const results = await axios.post(ep, {
      id: r.id,
      published: !r.published
    });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   */
  canSubUserEdit = () => {
    return !(
      !this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.Memberships?.edit
    );
  };

  /**
   * Makes request to backend to archive/unarchive membership.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveMembership = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/cancel/${this.state.idToEdit}`;
    const result = await axios.post(ep);
    const ep2 = `${process.env.REACT_APP_API}/partners/memberships/delete/${this.state.idToEdit}`;
    const results2 = await axios.post(ep2, { toArchive: this.state.toArchive });

    if (result.data.success && results2.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * Makes request to backend to hide a membership from archive.
   * Looks like a permanent deletion, but is only hidden from the partner's view.
   * Membership still stored in database
   */
  hideMembership = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/hide/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toHide: this.state.toHide });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  cancelMembershipApplications = async () => {
    let membershipCustomerArr = [];

    this.state.customers.forEach(customer => {
      if (customer.membershipId === this.state.idToEdit) {
        if (
          customer.status_stage === "Accepted_Unpaid" ||
          customer.status_stage === "New_Pending" ||
          customer.status_stage === "Waitlisted_Unpaid" ||
          customer.status_stage === "Accepted_Manual"
        ) {
          membershipCustomerArr.push({
            id: customer.id,
            programId: customer.membershipId
          });
        }
      }
    });

    console.log("MEMBERSHIP CUSTOMERS", membershipCustomerArr);

    const ep = `${process.env.REACT_APP_API}/partners/memberships/cancel-application/${this.state.idToEdit}`;
    const results = await axios.post(ep, { membershipCustomerArr });
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/memberships/list/${tab}`;
    console.time("initial_results_fetch");
    const results = await axios.get(ep);
    console.timeEnd("initial_results_fetch");

    const membership_count_ep = `${process.env.REACT_APP_API}/partners/memberships/list/count`;
    console.time("membership_count_fetch");
    const membership_count_results = await axios.get(membership_count_ep);
    console.timeEnd("membership_count_fetch");

    const membershipCount = {
      Memberships: membership_count_results.data.data.filter(
        e => !e.isArchived && !e.isHidden
      ),
      Archive: membership_count_results.data.data.filter(
        e => e.isArchived && !e.isHidden
      )
    };

    // Get customers to check if memberships have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("initial_membership_customers_fetch");
    const customers = await axios.get(customers_ep);
    console.timeEnd("initial_membership_customers_fetch");

    const previewOrderEp = `${process.env.REACT_APP_API}/partners/memberships/list/active`;
    const previewOrderResults = await axios.get(previewOrderEp);

    if (results.data.success) {
      results.data.data.forEach(e => {
        try {
          const plans = e.prices.filter(e => !JSON.parse(e).archived);
          const lowestPlan = plans.sort(
            (a, b) =>
              JSON.parse(a).price_per_term - JSON.parse(b).price_per_term
          )[0];
          e.price_per_term = JSON.parse(lowestPlan).price_per_term;
          e.membership_term = JSON.parse(lowestPlan).membership_term;
        } catch (e) {
          e.price_per_term = null;
          e.membership_term = null;
        }
      });

      // this.setState({
      //   data: {
      //     Memberships: results.data.data
      //       .filter(f => !f.isArchived)
      //       .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
      //     Archive: results.data.data
      //       .filter(f => f.isArchived && !f.isHidden)
      //       .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      //   },
      //   customers: customers.data.data
      // });
      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            previewOrderResults.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),
        membershipCount: membershipCount,
        customers: customers.data.data,
        previewOrder: results.data.data
          .filter(f => !f.isArchived && !f.isHidden && f.published)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order)
      });
    }

    const permissions = await this.getProgramPermissionsCount();
    this.setState({ membershipPermissions: permissions });
    this.setGlobal({ loading: false });
  };

  getProgramPermissionsCount = async () => {
    const feature_name = "membership";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/${feature_name}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      return resp.data.data;
    }
  };

  allowRestoreModalButton = () => {
    const { data, membershipPermissions } = this.state;
    if (data.Memberships && membershipPermissions) {
      return (
        membershipPermissions.has_access &&
        membershipPermissions.featureLimit > data.Memberships.length
      );
    }
    return false;
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1 && this.state.filterListChecked.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
    }

    const isPreview = value == "Preview Order";
    const isName1 = value == "Name (A to Z)";
    const isDate1 = value == "Created Date (new to old)";
    const isDate2 = value == "Update Date (new to old)";

    this.setState({
      filterListChecked: newChecked,
      isPreview: localStorage.setItem("isPreview", isPreview) || isPreview,
      isName1: localStorage.setItem("isName1", isName1) || isName1,
      isDate1: localStorage.setItem("isDate1", isDate1) || isDate1,
      isDate2: localStorage.setItem("isDate2", isDate2) || isDate2
    });
  }

  pipeData(allData) {
    if (allData.length > 0) {
      return {
        Memberships: allData.filter(e => e.classification === "active"),
        Archive: allData.filter(
          e => e.classification === "archive" && !e.isHidden
        )
      };
    } else {
      return {
        Memberships: [],
        Archive: []
      };
    }
  }

  filterData = () => {
    let data = this.state.allData;
    //const data = this.state.data.Active;
    console.log("data...", data);

    if (this.state.filter && this.state.filter !== "") {
      data = data.filter(membership => {
        const membershipName = membership.membership_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return membershipName.includes(filterText);
      });
    }

    // Filter by Preview Order
    if (this.state.isPreview) {
      const currentOrder = data
        .filter(f => !f.isArchived && !f.isHidden && f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);
      const filteredData = data
        .filter(f => f.isArchived || f.isHidden || !f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      data = [...currentOrder, ...filteredData];
    }

    // Filter by Created Date (New to Old)
    if (this.state.isDate1) {
      data.sort((a, b) => {
        if (new Date(b.createdAt) < new Date(a.createdAt)) {
          return -1;
        }
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Updated Date (New to Old)
    if (
      this.state.isDate2 ||
      (this.state.isPreview && this.state.currTab === 1)
    ) {
      data.sort((a, b) => {
        if (new Date(b.updatedAt) < new Date(a.updatedAt)) {
          return -1;
        }
        if (new Date(b.updatedAt) > new Date(a.updatedAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Program Name (A to Z)
    if (this.state.isName1) {
      data.sort((a, b) =>
        a.membership_name > b.membership_name
          ? 1
          : b.membership_name > a.membership_name
          ? -1
          : 0
      );
    }

    return {
      ...this.pipeData(data)
    };
  };

  filterDataCount() {
    let dataCount = { ...this.state.membershipCount };

    if (this.state.filter && this.state.filter !== "") {
      dataCount["Memberships"] = dataCount["Memberships"].filter(membership => {
        const membershipName = membership.membership_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return membershipName.includes(filterText);
      });
      dataCount["Archive"] = dataCount["Archive"].filter(membership => {
        const membershipName = membership.membership_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return membershipName.includes(filterText);
      });
    }

    return dataCount;
  }

  async getCurrTabData() {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/memberships/list/${tab}`;
    console.time("results_fetch");
    const results = await axios.get(ep);
    console.timeEnd("results_fetch");

    // Get customers to check if memberships have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("membership_customers_fetch");
    const customers = await axios.get(customers_ep);
    console.timeEnd("membership_customers_fetch");

    if (results.data.success) {
      results.data.data.forEach(e => {
        try {
          const plans = e.prices.filter(e => !JSON.parse(e).archived);
          const lowestPlan = plans.sort(
            (a, b) =>
              JSON.parse(a).price_per_term - JSON.parse(b).price_per_term
          )[0];
          e.price_per_term = JSON.parse(lowestPlan).price_per_term;
          e.membership_term = JSON.parse(lowestPlan).membership_term;
        } catch (e) {
          e.price_per_term = null;
          e.membership_term = null;
        }
      });

      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            results.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),

        customers: customers.data.data
      });
    }

    this.setGlobal({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.currTab !== this.state.currTab) {
      this.getCurrTabData();
      if (this.state.currTab === 0) {
        this.setState({
          isPreview: true,
          isDate1: false,
          isDate2: false,
          isName1: false
        });
        this.filterListLabels = [
          "Preview Order",
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)"
        ];
      } else {
        this.setState({
          isPreview: false,
          isDate1: false,
          isDate2: true,
          isName1: false
        });
        this.filterListLabels = [
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)"
        ];
      }
    }
  }

  togglePreviewUpdated() {
    this.setState({
      previewUpdated: false,
      currTab: 0,
      isPreview: true,
      isDate1: false,
      isDate2: false,
      isName1: false
    });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          {this.state.openPreviewMembershipListingsModal && (
            <PreviewMembershipListingsModal
              onClose={() =>
                this.setState({ openPreviewMembershipListingsModal: false })
              }
              onOrderSave={newOrder => {
                this.setState({ previewOrder: newOrder, previewUpdated: true });
                this.fetchInitialData();
              }}
              history={this.props.history}
              memberships={this.state.previewOrder}
            />
          )}
          {this.state.iframeSetUp && (
            <AddIframeModal
              iframe={this.state.selectedIframe}
              open={this.state.iframeSetUp}
              type={this.state.type}
              active={this.state.active}
              onClose={() =>
                this.setState({
                  iframeSetUp: false,
                  selectedIframe: null,
                  type: null,
                  active: null
                })
              }
            />
          )}
          {this.state.openCreateMembershipModal && (
            <CreateMembershipModal
              onClose={_ => this.setState({ openCreateMembershipModal: false })}
              dispatch={this.dispatch}
              history={this.props.history}
              setGlobal={this.setGlobal}
              global={this.global}
            />
          )}
          {this.state.showConfirmModal && (
            <ConfirmArchiveModal
              onSubmit={() => {
                this.archiveMembership();
              }}
              onClose={_ => this.setState({ showConfirmModal: false })}
              toArchive={this.state.toArchive}
              modalBody={
                "UPDATE: This functionality has been updated. " +
                "It will automatically deactivate all enrollments, and archive the membership. " +
                "All the program data will still be saved. " +
                "Please ensure you want to archive this membership. "
              }
              notAllowed={false}
              buttonName="Archive"
            />
          )}
          {this.state.showConfirmHideModal && (
            <ConfirmArchiveModal
              onSubmit={() => {
                if (this.state.cancelAllApplications) {
                  // Cancel all applications and then hide the OV
                  this.cancelMembershipApplications()
                    .then(() => this.hideMembership())
                    .catch(error => console.log(error));
                } else {
                  // Simply hide the OV
                  this.hideMembership();
                }
              }}
              onClose={_ => this.setState({ showConfirmHideModal: false })}
              toArchive={this.state.toArchive}
              isPermanent={this.state.toHide}
              modalBody={
                this.state.notAllowedToDelete
                  ? "Sorry, memberships with customers cannot be deleted."
                  : ""
              }
              notAllowed={false}
              cancelApps={this.state.cancelAllApplications}
            />
          )}
          {this.state.openViewArchivedModal && (
            <ViewArchivedMembershipDetailsModal
              program={this.filterData()["Archive"].filter(data => data.id === this.state.idToEdit)[0]}
              onClose={_ => this.setState({ openViewArchivedModal: false })}
            />
          )}
          <div className="row cont">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Memberships</h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "Memberships"
                      })
                    }
                    className="hovermyX"
                  />
                </div>

                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_membership_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_membership_listings:
                            !this.global.organizationInfo
                              .display_membership_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    color="primary"
                    disabled={this.canSubUserEdit()}
                  />
                  <i
                    data-tip
                    data-for="view-membership-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-membership-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles membership tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.setState({
                        openPreviewMembershipListingsModal: true
                      })
                    }
                  >
                    Order
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={
                      _ => this.setState({ openCreateMembershipModal: true })
                      // this.props.history.push("/partners/memberships/setup")
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  ======================== Start of Filter Button ====================== */}
          <div className="row cont">
            <div className="col-md-12">
              <div className="forbtnwrap justify-end">
                <button
                  className="newapplications"
                  style={{
                    border: "1px solid #ccc",
                    padding: "4px 20px",
                    borderRadius: "4px",
                    display: "block",
                    background: "#ccc",
                    fontWeight: "700",
                    color: "white"
                  }}
                  aria-describedby={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  onClick={this.handleFilterClick.bind(this)}
                >
                  Filter
                </button>
                <Popover
                  id={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  open={Boolean(this.state.popoverAnchorE1)}
                  anchorEl={this.state.popoverAnchorE1}
                  onClose={this.handleClose.bind(this)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <List
                    className={
                      this.state.currTab === 0
                        ? this.props.classes.filter
                        : this.props.classes.filterArchived
                    }
                    disablePadding={false}
                  >
                    {this.filterListLabels.map((value, i) => {
                      const labelId = `checkbox-list-label-${i}`;
                      return (
                        <ListItem
                          key={value}
                          role={undefined}
                          dense
                          button
                          onClick={_ => {
                            this.handleFilterListToggle(value);
                          }}
                        >
                          <ListItemIcon
                            classes={{ root: this.props.classes.checkbox }}
                          >
                            <Checkbox
                              name={value}
                              edge="start"
                              checked={
                                value === "Preview Order"
                                  ? this.state.isPreview
                                  : value === "Name (A to Z)"
                                  ? this.state.isName1
                                  : value === "Created Date (new to old)"
                                  ? this.state.isDate1
                                  : value === "Update Date (new to old)"
                                  ? this.state.isDate2
                                  : false
                              }
                              disabled={
                                !this.filterListLabels.filter(f => f == value)
                              }
                              tabIndex={-1}
                              disableRipple
                              primary
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Popover>
              </div>
            </div>
          </div>
          {/*  ======================== End of Filter Button ====================== */}
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%"
                }}
              >
                <i
                  className="fas fa-search"
                  style={{
                    padding: "8px",
                    background: "white",
                    marginTop: "10px"
                  }}
                />
                <input
                  type="text"
                  placeholder="Filter by membership name"
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1,
                    marginTop: "10px"
                  }}
                  onChange={e => this.setState({ 
                    filter: e.target.value,
                    page: 0
                  })}
                />
              </div>
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.filterData()}
                  dataCount={this.filterDataCount()}
                  actions={this.actions}
                  page={"Listings>Memberships"}
                  isFirstTab={this.state.previewUpdated}
                  resetPreview={() => this.togglePreviewUpdated()}
                  setCurrTab={tab => {
                    this.setState({ currTab: tab });
                  }}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MembershipList);
