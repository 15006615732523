import React from "reactn";
import FranchiserOrdersModal from "../../../../components/modals/FranchiserOrdersModal";
import FranchiserTrackOrders from "./FranchiserTrackOrders";
import axios from "axios";

class FranchiserOrders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partnerId: this.global.franchiseUser.selectedId,
      partnerOrg: {
        user: {},
        orderInfo: {
          pendingCount: 0,
          paidCount: 0,
          refunds: 0,
          totalEarnings: 0
        }
      },
      data: {
        Pending: [],
        Payments: []
      },
    }
  }
  async componentDidMount() {
    await this.fetchInitialData();
  }

  async componentDidUpdate() {
    if (this.state.partnerId !== this.global.franchiseUser.selectedId) {
      this.setState({partnerId: this.global.franchiseUser.selectedId}, async () => {
        await this.fetchInitialData();
      }
      );
    }
  }
  
  async fetchInitialData() {
    this.setGlobal({loading: true});
    const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const ep2 = `${process.env.REACT_APP_API}/franchise/get-partner/${this.state.partnerId}`;
    const res = await axios.get(ep, { params: { partnerId: this.state.partnerId } });

    if (res.data?.success) {
      const pending = res.data.data.Once.pending
        .filter(e => !e.hidden)
        .sort(
          (e1, e2) =>
            new Date(e2.date).getTime() - new Date(e1.date).getTime()
        );
      const payments = [
        ...res.data.data.Once.failed,
        ...res.data.data.Once.payments
      ].filter(e => !e.hidden)
        .sort(
          (e1, e2) =>
            new Date(e2.date).getTime() - new Date(e1.date).getTime()
        );
            
      this.setState({
        data: {
          Pending: pending,
          Payments: payments
        },
        partnerOrg: {
          ...this.state.partnerOrg,
          orderInfo: {
            pendingCount: pending.length,
            paidCount: payments.length,
            failedCount: res.data.data.Once.failed.length,
            totalEarnings: res.data.data.Once.payments.reduce(
              (prev, curr) => prev + parseInt(curr.total) / 100, 0
            ),
            refunds: res.data.data.Once.payments.reduce(
              (prev, curr) => prev + parseInt(curr.refundedAmount) / 100, 0
            )
          }
        }
      });
    } else {
      console.error("Something went wrong fetching the data");
    }

    const res2 = await axios.get(ep2);
    if (res2.data.success) {
      this.setState({
        partnerOrg: {
          ...this.state.partnerOrg,
          user: res2.data.data
        }
      })
    } else {
      console.error("Something went wrong while fetching for Partner");
    }
    this.setGlobal({loading: false});
  }

  render() {
    return (
      <div className="container-fluid my_earning">
        <div className="cont heading-wrapper">
          <h1 className="page-heading">Order Tracking</h1>
        </div>
        <div className="cont">
          <FranchiserOrdersModal partnerOrg={this.state.partnerOrg} />
          <div className="boxes">
            <FranchiserTrackOrders data={this.state.data} />
          </div>
        </div>
      </div>
    );
  }
};

export default FranchiserOrders;