import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "semantic-ui-react";
import AddAddress from "../../../../components/modals/AddAddressModal";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
    this.theme = [`FF6663`, `039BE5`, `E57403`, `1C2680`, `079475`];

    this.customForm = this.customForm.bind(this);
  }
  handleChange = (e, v) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        [e]: v
      }
    });
  };

  //Loading the initial program category from the create program modal
  componentDidMount() {
    this.props.initialProgramCategory &&
      this.setGlobal({
        online: {
          ...this.global.online,
          program_category: this.props.initialProgramCategory,
          application_required: this.props.initialApplicationRequired,
          transaction_cost: this.props.initialTransactionCost,
          addChild: this.props.initialAddChild,
          auto_withdraw: this.props.initialAutoWithdraw,
          isRecurring: this.props.initialIsRecurring,
          allow_same_enrollment: this.props.allowSameEnrollment
        }
      });
  }

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          {this.state.openAddress && (
            <AddAddress
              onClose={_ => this.setState({ openAddress: false })}
              open={this.state.openAddress}
              addLoc={address => {
                const other_locs = [
                  ...this.global.organizationInfo.other_locations
                ];
                other_locs.push(address);
                this.locations.push(address);
                this.setGlobal({
                  organizationInfo: {
                    ...this.global.organizationInfo,
                    other_locations: [...other_locs]
                  },
                  online: {
                    ...this.global.online,
                    address1: address
                  }
                });
                this.dispatch.saveOrganizationalProfile();
              }}
            />
          )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Category<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.MultiProgramCategories
                  value={this.global.online.program_category}
                  name="program_category"
                  onChange={this.handleChange}
                  isDisabled={
                    this.global.online.program_category ? true : false
                  }
                ></formTypes.MultiProgramCategories>
              </div>
            </div>
          </div>

          {this.global.online.program_category === "Single Sessions" && (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Program Structure<span className="requiredField">*</span>
                </h3>
              </div>
              <div className="col-7 d-flex">
                <div className={`w-100`}>
                  <select
                    className="input-fields"
                    style={{ width: "100%" }}
                    name="program_details"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    value={this.global.online.program_details}
                  >
                    <option value="">Please Select</option>
                    <option>Lessons / Sessions</option>
                    <option>Classes</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Name<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="program_name"
                  value={this.global.online.program_name}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Color<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Pick a Color"
                fluid
                selection
                onChange={(_, { value }) => {
                  this.setGlobal({
                    online: {
                      ...this.global.online,
                      program_color: value
                    }
                  });
                }}
                value={this.global.online.program_color}
                options={this.theme.map((e, i) => ({
                  text: `Color ${i + 1}`,
                  value: i,
                  key: e,
                  image: {
                    bordered: true,
                    src: `https://dummyimage.com/1220/${e}/${e}`
                  }
                }))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">All Ages</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <Switch
                  name="all_ages"
                  checked={this.global.online.all_ages}
                  onClick={_ => {
                    this.handleChange("all_ages", !this.global.online.all_ages);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Age Groupings</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.ChildAgeGroupSelect
                value={
                  this.global.online.age_groupings &&
                  this.global.online.age_groupings.map(e =>
                    typeof e === "string" ? JSON.parse(e) : e
                  )
                }
                name="age_groupings"
                onChange={this.handleChange}
                isDisabled={this.global.online.all_ages}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Target Age(s)</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.Select
                  name="Select Name"
                  options={new Array(60).fill(0).map((_, i) => ({
                    label: i === 1 ? `${i} year` : `${i} years`,
                    value: i
                  }))}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.online.age_range;
                    if (parseInt(ageEntered) > parseInt(prevRange[1])) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("age_range", [ageEntered, ageEntered]);
                    } else {
                      this.handleChange("age_range", [
                        ageEntered,
                        prevRange[0]
                      ]);
                    }
                  }}
                  value={this.global.online.age_range[0]}
                  isDisabled={this.global.online.all_ages}
                />
              </div>
              To
              <div className={`w-100`}>
                <formTypes.Select
                  name="Section One Select"
                  options={new Array(60).fill(0).map((_, i) => ({
                    label: i === 0 ? `${i + 1} year` : `${i + 1} years`,
                    value: i + 1
                  }))}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.online.age_range;
                    if (parseInt(ageEntered) < parseInt(prevRange)) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("age_range", [ageEntered, ageEntered]);
                    } else {
                      this.handleChange("age_range", [
                        prevRange[0],
                        ageEntered
                      ]);
                    }
                  }}
                  value={this.global.online.age_range[1]}
                  isDisabled={this.global.online.all_ages}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">
                Description<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  className="input-text-fields"
                  rows="8"
                  style={{ width: "100%" }}
                  name="description"
                  value={this.global.online.description}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Program Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
