import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import {
  format,
  addMonths,
  addWeeks,
  getDay,
  subDays,
  isAfter
} from "date-fns";
import "../../../assets/css/componentSpecificCss/invoiceBuilder.css";
import DatePicker from "react-datepicker";
import InvoiceDatePicker from "./components/invoiceDatePicker";
import AddInvoiceLineItemModal from "../../../components/modals/AddInvoiceLineItemModal";
import AddDiscountModal from "../../../components/modals/AddDiscountModal";
import EditDiscountModal from "../../../components/modals/EditDiscountModal";
import CreateDiscountModal from "../../../components/modals/CreateDiscountModal";
import AddTaxModal from "../../../components/modals/AddTaxModal";
import AddTotalDiscountModal from "../../../components/modals/AddTotalDiscountModal";
import CancelConfirmationModal from "../../../components/modals/CancelConfirmationModal";
import SendInvoiceEmailModal from "../../../components/modals/SendInvoiceEmailModal";
import ConfirmSendEmailModal from "../../../components/modals/ConfirmSendEmailModal";
import EditInvoiceQuantityModal from "../../../components/modals/EditInvoiceQuantityModal";
import EditItemModal from "../../../components/modals/EditItemModal";
import PaidOfflineModal from "../../../components/modals/PaidOfflineModal";
import SetDraftModal from "../../../components/modals/SetDraftModal";
import ReactTooltip from "react-tooltip";

import { withRouter } from "react-router-dom";

import { useInvoiceItemData } from "./InvoiceBuilderUtil";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Edit, Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
class InvoiceBuilder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      invoiceDate: new Date(),
      invoiceDueDate: addWeeks(new Date(), 1),
      serviceStartDate: new Date(),
      serviceEndDate: addMonths(new Date(), 1),
      invoice_status: "Draft",
      isUpdating: false,
      customer: [
        {
          displayName: "",
          email: "",
          address: "",
          city: "",
          province: "",
          postalCode: ""
        }
      ],
      invoiceDetails: [],
      bodyData: [],
      footData: [],
      anchorEl: null,
      anchorE2: null,
      openCreateDiscountModal: false,
      openAddTotalDiscountModal: false,
      openAddTaxModal: false,
      finalTotal: 0,
      subTotal: 0,
      discountTotal: 0,
      taxAmount: 0,
      message: "",
      referenceNumber: "N/A"
    };
    this.editInvoice = this.editInvoice.bind(this);
    this.updateInvoice = this.updateInvoice.bind(this);
    this.headings = [
      { label: "Item Name", style: { textAlign: "left" } },
      { label: "Rate", style: { textAlign: "right" } },
      { label: "Discount", style: { textAlign: "right" } },
      { label: "Quantity", style: { textAlign: "right" } },
      { label: "Amount", style: { textAlign: "right" } },
      { label: "", style: { textAlign: "right" } }
    ];

    this.body = [
      [
        { value: "Music School" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$1000" }
      ],
      [
        { value: "Coupon: 10% Off" },
        { value: "" },
        { value: "-$100" },
        { value: "" },
        { value: "" }
      ],
      [
        { value: "Sub-Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$900", style: { fontWeight: "bold", color: "black" } }
      ]
    ];

    this.foot = [
      [
        { value: "Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "$980", style: { fontWeight: "bold", color: "black" } }
      ]
    ];
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  async editInvoice() {
    const ep = `${process.env.REACT_APP_API}/partners/orders/edit`;

    const { finalTotal, subTotal, discountTotal } = useInvoiceItemData(
      this.state.invoiceDetails,
      this.state.data
    );
    this.setState({
      finalTotal: finalTotal,
      subTotal: subTotal,
      discountTotal: discountTotal
    });

    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      amount: finalTotal,
      itemAmount: Math.round(subTotal),
      discountAmount: discountTotal
    });
    if (res.data.success) {
      this.setState({ isUpdating: true, invoice_status: "Draft" });
      if (res.data.data.type === "Invoice" && res.data.data.orderStartDate && isAfter(new Date(), new Date(res.data.data.orderStartDate))) {
        this.setState({ 
          invoiceDate: new Date(),
          data: {
            ...this.state.data,
            orderStartDate: new Date()
          }
        });
      }
    } else {
      toast.error(res.data.message);
    }
  }

  async updateInvoice() {
    this.setState({ openSendEmailModal: true });
  }

  fetchInitialData = async () => {
    const { isNewCustomer, selectedCustomer } = this.props.location.state;

    console.log("isNewCustomer", isNewCustomer);
    console.log("selectedCustomer", selectedCustomer);
    if (selectedCustomer) {
      console.log("selectedCustomer block");
      this.setState({ customer: selectedCustomer, isNewCustomer });
    }

    console.log("this.props.match.params.id", this.props.match.params.id);

    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/draft/${this.props.match.params.id}`;
    const results = await axios.get(ep);
    console.log("results", results);

    if (results.data.success) {
      const details = results.data.data.invoice_details;
      // let { bodyData, footData, finalTotal, subTotal, discountTotal, tax } =
      let { finalTotal, subTotal, discountTotal, tax } = useInvoiceItemData(
        details,
        results.data.data
      );

      if (results.data.data.taxAmount > 0) {
        tax = results.data.data.taxAmount;
      }
      const ep2 = `${process.env.REACT_APP_API}/partners/orders/update`;
      const results2 = await axios.post(ep2, {
        id: this.props.match.params.id,
        amount: finalTotal,
        itemAmount: Math.round(subTotal),
        discountAmount: discountTotal,
        taxAmount: tax
      });
      console.log("results2", results2);

      let { bodyData, footData } = useInvoiceItemData(
        details,
        results2.data.data
      );

      this.setState({
        customer: [results.data.data.customer],
        isNewCustomer: !!results.data.data.customer.isGuest,
        invoiceDetails: details,
        bodyData,
        footData,
        isUpdating: (results.data.data.updatedTimes.length > 0 && results.data.data.invoice_status === "Draft"),
        data: results.data.data,
        invoice_status: results.data.data.invoice_status,
        invoiceDate: (
          results.data.data.type === "Invoice" ? (results.data.data.orderStartDate ? new Date(results.data.data.orderStartDate) : null) : new Date(results.data.data.createdAt)
        ),
        invoiceDueDate:
          results.data.data.due_date !== null
            ? new Date(results.data.data.due_date)
            : this.state.invoiceDueDate,
        serviceStartDate:
          results.data.data.service_start_date !== null
            ? new Date(results.data.data.service_start_date)
            : this.state.serviceStartDate,
        serviceEndDate:
          results.data.data.service_end_date !== null
            ? new Date(results.data.data.service_end_date)
            : this.state.serviceEndDate,
        next_invoice_date: results.data.data.next_invoice_date ? new Date(results.data.data.next_invoice_date) : null,
        finalTotal: finalTotal,
        subTotal: subTotal,
        discountTotal: discountTotal,
        taxAmount: tax,
        message: results.data.data.invoice_message
      });
    }

    this.setState(
      {
        referenceNumber: `po${results.data.data.id}, uid${results.data.data.dsUserId}`
      },
      () => console.log("reference number", this.state.referenceNumber)
    );
    if (this.state.data?.type === "Recurring") {
      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Recurring Payments", to: "/recurring" },
          {
            label: this.state.data.customer.displayName,
            to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
          }
        ]
      });
    } else {
      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Order Tracking", to: "/orders" },
          {
            label: this.state.data.customer.displayName,
            to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
          }
        ]
      });
    }
  };

  editLineItem = async items => {
    console.log("lineItem", items);

    this.setState({
      invoiceDetails: items
    });

    const { bodyData, footData, finalTotal, subTotal, discountTotal } =
      useInvoiceItemData(items, this.state.data);

    console.log("bodyData", bodyData);
    console.log("footData", footData);

    this.setState({ bodyData, footData, finalTotal, subTotal, discountTotal });

    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const results = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_details: items,
      amount: finalTotal,
      itemAmount: Math.round(subTotal),
      discountAmount: discountTotal
    });

    console.log("results on DB", results);
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  saveLineItem = async item => {
    try {
      console.log("lineItem", item);

      const id = this.state.invoiceDetails.length;

      let data = this.state.invoiceDetails;
      if (item.id) {
        // When an invoice line item is existing or newly created
        const lineData = {
          itemName: item.itemName,
          rate: Math.round(parseFloat(item.rate)),
          description: item.description,
          discountId: null,
          discount: null,
          quantity: item.quantity ? parseInt(item.quantity) : 1,
          amount: Math.round(parseFloat(item.rate)),
          id: id,
          serviceStartDate: null,
          serviceEndDate: null,
          pricingItemId: item.id
        };
        data = [...this.state.invoiceDetails, lineData];
        this.setState({
          invoiceDetails: data
        });
      } else {
        // When an invoice item is not saved on our tables
        const lineData = {
          itemName: item.itemName,
          rate: Math.round(parseFloat(item.rate)),
          description: item.description,
          discountId: null,
          discount: null,
          quantity: item.quantity ? parseInt(item.quantity) : 1,
          amount: Math.round(parseFloat(item.rate)),
          id: id,
          serviceStartDate: null,
          serviceEndDate: null
        };
        data = [...this.state.invoiceDetails, lineData];

        this.setState({
          invoiceDetails: data
        });
      }
      const { bodyData, footData, finalTotal, subTotal, discountTotal } =
        useInvoiceItemData(data, this.state.data);

      console.log("bodyData", bodyData);
      console.log("footData", footData);

      this.setState({ bodyData, footData, finalTotal, subTotal, discountTotal });

      const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
      const results = await axios.post(ep, {
        id: this.props.match.params.id,
        invoice_details: data,
        amount: finalTotal,
        itemAmount: Math.round(subTotal),
        discountAmount: discountTotal
      });

      console.log("results on DB", results);
      if (results.data.success) {
      }
      this.fetchInitialData();
      
    } catch (e) {
      console.log(e);
    }
  };

  removeItem = async row => {
    console.log("removeItem", row);
    console.log("this.state.selectedItemIndex", this.state.selectedItemIndex);
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    let inv_details = [...this.state.invoiceDetails];
    inv_details.splice(this.state.selectedItemIndex, 1);
    console.log("inv_details", inv_details);

    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_details: inv_details
    });

    console.log("res", res);
    if (res.data.success) {
      this.setState({ anchorEl: null, anchorE2: null });
      toast.success("Removed Invoice Item");
      this.fetchInitialData();
    }
  };

  removeTotalDiscount = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_discount: null
    });

    console.log("res", res);
    if (res.data.success) {
      toast.success("Removed Invoice Discount");
      this.fetchInitialData();
    }
  };

  removeTax = async () => {
    console.log("removeTax");
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_tax: null,
      taxAmount: 0
    });

    console.log("res", res);
    if (res.data.success) {
      toast.success("Removed Invoice Tax");
      this.fetchInitialData();
    }
  };

  updateInvoiceStatus = async status => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    const { finalTotal, subTotal, discountTotal } = useInvoiceItemData(
      this.state.invoiceDetails,
      this.state.data
    );
    this.setState({
      finalTotal: finalTotal,
      subTotal: subTotal,
      discountTotal: discountTotal
    });

    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_status: status,
      amount: finalTotal,
      itemAmount: Math.round(subTotal),
      discountAmount: discountTotal
    });

    console.log("res", res);
    if (res.data.success) {
      console.log("updated");
      // toast.success("Removed Invoice Discount");
      this.fetchInitialData();
    }
  };

  updateInvoiceDate = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      service_start_date: this.state.serviceStartDate,
      service_end_date: this.state.serviceEndDate,
      due_date: this.state.invoiceDueDate,
      orderStartDate: this.state.invoiceDate,
      next_invoice_date: this.state.next_invoice_date
    });
    console.log("res Date Updates", res);
    if (res.data.success) {
      console.log("updated");
      // toast.success("Removed Invoice Discount");
      /*
      this.setState({
        invoiceDate: this.state.invoiceDate,
        invoiceDueDate: this.state.invoiceDueDate,
        serviceStartDate: this.state.serviceStartDate,
        serviceEndDate: this.state.serviceEndDate,
        next_invoice_date: this.state.next_invoice_date
      });
      */
      this.fetchInitialData();
    }
  };

  sendTestInvoiceEmailToPartner = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/send-test-email`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_message: this.state.message
    });
    console.log("res", res);
    if (res.data.success) {
      toast.success("Test Email sent");
      this.fetchInitialData();
    }
  };

  saveAndGoBack = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      amount: Math.round(this.state.finalTotal),
      itemAmount: Math.round(this.state.subTotal)
      // discountAmount: Math.round(this.state.discountTotal)
    });

    console.log("res Date Updates", res);
    if (res.data.success) {
      if (this.state.data.type === "Recurring") {
        this.props.history.push("/partners/recurring");
      } else {
        this.props.history.push("/partners/orders");
      }
    }
  };

  render() {
    console.log("this.props", this.props);
    console.log("this.state", this.state);
    console.log("this.global", this.global);

    if (this.state.customer.displayName === "") return null;
    const { customer } = this.state;

    let minNextDate;
    if (
      this.state.data?.next_invoice_date &&
      this.state.data?.recurring_frequency
    ) {
      const created = new Date(this.state.data.createdAt);
      switch (this.state.data.recurring_frequency) {
        case "week":
          minNextDate = addWeeks(new Date(created), 1);
          break;
        case "bi-week":
          minNextDate = addWeeks(new Date(created), 2);
          break;
        case "month":
          minNextDate = addMonths(new Date(created), 1);
          break;
        default:
          break;
      }
    }
    console.log(minNextDate);

    return (
      <div className="admin">
        {this.state.openAddItemModal && (
          <AddInvoiceLineItemModal
            onClose={_ => {
              this.setState({ openAddItemModal: false });
            }}
            lineItem={e => this.saveLineItem(e)}
          />
        )}

        {this.state.openAddDiscountModal && (
          <AddDiscountModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openAddDiscountModal: false });
            }}
            openCreateDiscountModal={e =>
              this.setState({
                openCreateDiscountModal: e,
                openAddDiscountModal: false
              })
            }
            addedDiscount={e => this.fetchInitialData()}
          />
        )}

        {this.state.openCreateDiscountModal && (
          <CreateDiscountModal
            addedDiscount={e =>
              this.setState({
                openAddDiscountModal: true,
                openCreateDiscountModal: false
              })
            }
            onClose={_ => {
              this.setState({ openCreateDiscountModal: false });
            }}
          />
        )}

        {this.state.openEditDiscountModal && (
          <EditDiscountModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditDiscountModal: false });
            }}
            removeDiscount={_ => this.fetchInitialData()}
            editDiscount={e => this.fetchInitialData()}
          />
        )}

        {this.state.openAddTotalDiscountModal && (
          <AddTotalDiscountModal
            addedDiscount={e => this.fetchInitialData()}
            footData={this.state.footData}
            onClose={_ => {
              this.setState({ openAddTotalDiscountModal: false });
            }}
            subTotal={this.state.subTotal}
          />
        )}

        {this.state.openAddTaxModal && (
          <AddTaxModal
            onClose={() => this.setState({ openAddTaxModal: false })}
            addedTax={e => this.fetchInitialData()}
          />
        )}

        {this.state.openCancelConfirmationModal && (
          <CancelConfirmationModal
            confirmCancel={e => this.fetchInitialData()}
            id={this.props.match.params.id}
            onClose={_ => {
              this.setState({ openCancelConfirmationModal: false });
            }}
          />
        )}

        {this.state.openSendEmailModal && (
          <SendInvoiceEmailModal
            sendMessage={e =>
              this.setState({ message: e, openConfirmSendEmailModal: true })
            }
            data={this.state.data}
            onClose={_ => {
              this.setState({ openSendEmailModal: false });
            }}
            customer={this.state.customer}
          />
        )}

        {this.state.openConfirmSendEmailModal && (
          <ConfirmSendEmailModal
            data={this.state.data}
            message={this.state.message}
            sendMessage={e => this.setState({ openSendEmailModal: false })}
            onClose={_ => {
              this.setState({ openConfirmSendEmailModal: false });
              this.updateInvoiceDate();
              //this.fetchInitialData();
            }}
            isUpdating={this.state.isUpdating}
          />
        )}

        {this.state.openEditQuantityModal && (
          <EditInvoiceQuantityModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditQuantityModal: false });
              this.fetchInitialData();
            }}
          />
        )}

        {this.state.openEditItemModal && (
          <EditItemModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openEditItemModal: false });
              // this.fetchInitialData();
            }}
            lineItem={e => {
              console.log("e", e);
              this.editLineItem(e);
            }}
          />
        )}

        {this.state.openPaidOfflineModal && (
          <PaidOfflineModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openPaidOfflineModal: false });
              // this.fetchInitialData();
            }}
            refreshPage={e => {
              this.fetchInitialData();
            }}
            organizationName={this.global.organizationInfo.organization_title}
            userEmail={customer[0].email}
            userName={customer[0].displayName}
            totalAmount={this.state.finalTotal}
          />
        )}

        {this.state.openSetDraftModal && (
          <SetDraftModal
            selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails}
            onClose={_ => {
              this.setState({ openSetDraftModal: false });
              // this.fetchInitialData();
            }}
            refreshPage={e => {
              this.fetchInitialData();
            }}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div style={{ paddingLeft: "0px" }} className="cont">
            <h1>{""}</h1>
          </div>

          <div className="invoice-status-container">
            <div className="invoice-status-inner">
              <div className="invoice-status-label">INVOICE STATUS</div>

              <div className="invoice-status-content">
                {this.state.invoice_status === "Draft" ||
                  this.state.invoice_status === "Cancelled" ||
                  this.state.invoice_status === "Ready to Bill" ? (
                  <select
                    name="invoice_status"
                    id="invoice_status"
                    type="text"
                    className="status-select-control"
                    disabled={
                      this.state.invoice_status === "Cancelled"
                    }
                    // style={{ width: "100%" }}
                    onChange={e => {
                      if (e.target.value === "Cancelled") {
                        this.setState({
                          openCancelConfirmationModal: true
                        });
                        return;
                      }

                      // if(e.target.value === "Ready to Bill"){

                      // }

                      this.setState({
                        [e.target.name]: e.target.value
                      });
                      this.updateInvoiceStatus(e.target.value);
                    }}
                    value={this.state.invoice_status}
                  >
                    <option value="Draft">Draft</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Ready to Bill">Ready to Bill</option>
                  </select>
                ) : (
                  <select
                    name="invoice_status"
                    id="invoice_status"
                    type="text"
                    className="status-select-control"
                    disabled={
                      this.state.invoice_status === "Cancelled" ||
                      this.state.invoice_status === "Issued" ||
                      this.state.invoice_status === "Paid Offline"
                    }
                    // style={{ width: "100%" }}
                    onChange={e => {
                      if (e.target.value === "Cancelled") {
                        this.setState({
                          openCancelConfirmationModal: true
                        });
                        return;
                      }
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                      this.updateInvoiceStatus(e.target.value);
                    }}
                    value={this.state.invoice_status}
                  >
                    <option value="Issued">Issued</option>
                    <option value="Draft">Draft</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Paid Offline">Paid Offline</option>
                  </select>
                )}
              </div>
            </div>
          </div>

          <div
            className="invoice-receipt-container"
            style={{ marginBottom: "10px" }}
          >
            <div className="invoice-recipt-header-container">
              <div
                className="order-header-background"
                style={{
                  minHeight: 270,
                  backgroundColor: "white",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "26px",
                  borderTopRightRadius: "26px"
                }}
              ></div>

              {/* {this.state.data.logo && (
                <div className="order-logo-wrapper">
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.logo}`}
                    alt="Org logo"
                    className="order-logo"
                  />
                </div>
              )} */}

              <div className="order-name-section">
                <div className="invoice-name">
                  {this.global.organizationInfo.organization_title}
                </div>
                <div className="invoice-email">
                  {this.global.organizationInfo.address1}
                  {this.global.organizationInfo.address2
                    ? `, ${this.global.organizationInfo.address2}`
                    : ""}
                </div>
                <div className="invoice-email">
                  {this.global.organizationInfo.city}
                  {this.global.organizationInfo.province
                    ? `, ${this.global.organizationInfo.province}`
                    : ""}
                  {this.global.organizationInfo.country
                    ? `, ${this.global.organizationInfo.country}`
                    : ""}
                  {this.global.organizationInfo.postalcode
                    ? "\xa0\xa0" + this.global.organizationInfo.postalcode
                    : ""}
                </div>
                <div className="invoice-email">{this.global.dsUser.email}</div>
                <div className="invoice-email">{this.global.dsUser.phone}</div>
                {this.global.organizationInfo.tax_code !== "" && (
                  <div style={{ fontSize: "14px" }}>
                    <strong>Tax Code: </strong>
                    {this.global.organizationInfo.tax_code}
                  </div>
                )}
              </div>

              <InvoiceDatePicker
                invoiceStatus={this.state.invoice_status}
                invoiceDate={this.state.invoiceDate}
                serviceStartDate={this.state.serviceStartDate}
                serviceEndDate={this.state.serviceEndDate}
                invoiceDueDate={this.state.invoiceDueDate}
                invoiceDateChange={e => {
                  this.setState({ invoiceDate: e }, _ =>
                    this.updateInvoiceDate()
                  );
                }}
                serviceStartDateChange={e => {
                  this.setState({ serviceStartDate: e }, _ =>
                    this.updateInvoiceDate()
                  );
                }}
                serviceEndDateChange={e => {
                  this.setState({ serviceEndDate: e }, _ =>
                    this.updateInvoiceDate()
                  );
                }}
                invoiceDueDateChange={e => {
                  this.setState({ invoiceDueDate: e }, _ =>
                    this.updateInvoiceDate()
                  );
                }}
              />

              <div className="invoice-billing-info-section">
                <div className="invoice-bill-title">Bill To:</div>
                <div className="invoice-bill-name">
                  {customer[0].displayName}
                </div>
                {this.state.isNewCustomer ? (
                  <div className="invoice-bill-address1">
                    {customer[0].email}
                  </div>
                ) : (
                  <>
                    <div className="invoice-bill-address1">
                      {customer[0].address}
                    </div>
                    <div className="invoice-bill-address2">
                      {customer[0].city}
                      {customer[0].province ? `, ${customer[0].province}` : ""}
                      {customer[0].postalCode
                        ? `, ${customer[0].postalCode}`
                        : ""}
                    </div>
                    <div className="invoice-bill-address1">
                      {customer[0].email}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="invoice-receipt-table-container">
              <table className="order-receipt-table">
                <thead>
                  <tr className="order-receipt-header-section">
                    {this.headings?.map(heading => {
                      return (
                        <th
                          key={heading.label}
                          style={heading.style}
                          className="invoice-receipt-table-header"
                        >
                          {heading.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {this.state.bodyData?.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex} className="order-receipt-item-section">
                        {row.map((cell, cellIndex) => {
                          if (cell.type === "action") {
                            return (
                              <td
                                key={cellIndex}
                                style={this.headings?.[cellIndex].style}
                                className={`invoice-receipt-item-menu ${this.headings?.[cellIndex].className || ""
                                  }`}
                              >
                                {cell.isAddDiscount ? (
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{
                                      color: "#2639d2",
                                      marginRight: "20px",
                                      cursor: "pointer",
                                      position: "relative"
                                    }}
                                    onClick={event => {
                                      if (
                                        this.state.invoice_status ===
                                        "Cancelled" ||
                                        this.state.invoice_status ===
                                        "Issued" ||
                                        this.state.invoice_status ===
                                        "Paid Offline"
                                      )
                                        return;
                                      this.setState({
                                        anchorEl: event.currentTarget,
                                        selectedItemIndex: cell.indexValue
                                      });
                                    }}
                                    aria-label="more"
                                    aria-controls="add-menu"
                                    aria-haspopup="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{
                                      color: "#2639d2",
                                      marginRight: "20px",
                                      cursor: "pointer",
                                      position: "relative"
                                    }}
                                    onClick={event => {
                                      if (
                                        this.state.invoice_status ===
                                        "Cancelled" ||
                                        this.state.invoice_status ===
                                        "Issued" ||
                                        this.state.invoice_status ===
                                        "Paid Offline"
                                      )
                                        return;
                                      this.setState({
                                        anchorE2: event.currentTarget,
                                        selectedItemIndex: cell.indexValue
                                      });
                                    }}
                                    aria-label="more"
                                    aria-controls="edit-menu"
                                    aria-haspopup="true"
                                  ></i>
                                )}
                                <Menu
                                  id="add-menu"
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={_ =>
                                    this.setState({ anchorEl: null })
                                  }
                                  PaperProps={{
                                    style: {
                                      // maxHeight: "45px",
                                      // width: '100%',
                                      minHeight: "30px",
                                      margin: 0,
                                      display: "flex",
                                      justifyContent: "center"
                                    }
                                  }}
                                >
                                  <MenuItem
                                    key="add-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openAddDiscountModal: true,
                                        selectedItem: row,
                                        anchorEl: null
                                      });
                                    }}
                                  >
                                    Add Discount
                                  </MenuItem>
                                  <MenuItem
                                    key="edit-item"
                                    onClick={_ =>
                                      this.setState({
                                        openEditItemModal: true,
                                        selectedItem: row,
                                        anchorEl: null
                                      })
                                    }
                                  >
                                    Edit Item
                                  </MenuItem>
                                  <MenuItem
                                    key="remove-item"
                                    onClick={_ => this.removeItem(row)}
                                  >
                                    Remove Item
                                  </MenuItem>
                                </Menu>

                                <Menu
                                  id="edit-menu"
                                  anchorEl={this.state.anchorE2}
                                  keepMounted
                                  open={Boolean(this.state.anchorE2)}
                                  onClose={_ =>
                                    this.setState({ anchorE2: null })
                                  }
                                  PaperProps={{
                                    style: {
                                      // maxHeight: "45px",
                                      // width: '100%',
                                      minHeight: "30px",
                                      margin: 0,
                                      display: "flex",
                                      justifyContent: "center"
                                    }
                                  }}
                                >
                                  <MenuItem
                                    key="edit-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openEditDiscountModal: true,
                                        selectedItem: row,
                                        anchorE2: null
                                      });
                                    }}
                                  >
                                    Edit Discount
                                  </MenuItem>
                                  <MenuItem
                                    key="edit-line-item"
                                    onClick={_ => {
                                      console.log("edit-line-item");
                                      this.setState({
                                        anchorE2: null,
                                        openEditItemModal: true,
                                        selectedItem: row
                                      });
                                    }}
                                  >
                                    Edit Item
                                  </MenuItem>
                                  <MenuItem
                                    key="remove-item"
                                    onClick={_ => {
                                      console.log("remove item");
                                      this.removeItem(row);
                                    }}
                                  >
                                    Remove Item
                                  </MenuItem>
                                </Menu>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={cellIndex}
                              style={this.headings?.[cellIndex].style}
                              className={`invoice-receipt-item-content ${this.headings?.[cellIndex].className || ""
                                }`}
                            >
                              <span style={cell.style}>{cell.value}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                  {this.state.invoice_status !== "Cancelled" &&
                    this.state.invoice_status !== "Issued" &&
                    this.state.invoice_status !== "Paid Offline" && (
                      <tr className="add-item-btn-section">
                        <td
                          className="add-item-btn"
                          onClick={_ => {
                            if (
                              this.state.invoice_status === "Cancelled" ||
                              this.state.invoice_status === "Issued" ||
                              this.state.invoice_status === "Paid Offline"
                            )
                              return;
                            this.setState({ openAddItemModal: true });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <span>Add Item</span>
                          </div>
                        </td>
                      </tr>
                    )}
                </tbody>

                <tfoot>
                  {this.state.footData?.map((row, rowIndex) => {
                    return (
                      <tr
                        key={rowIndex}
                        className="invoice-receipt-footer-section"
                      >
                        {row.map((cell, cellIndex) => {
                          return (
                            <td
                              key={cellIndex}
                              style={this.headings?.[cellIndex].style}
                              className={`invoice-receipt-item-content ${this.headings?.[cellIndex].className || ""
                                }`}
                            >
                              <span style={cell.style}>{cell.value}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tfoot>
              </table>

              <div className="total-discount-container">
                {this.state.invoice_status !== "Cancelled" &&
                  this.state.invoice_status !== "Issued" &&
                  this.state.invoice_status !== "Paid Offline" && (
                    <div
                      className="discount-total"
                      onClick={_ => {
                        if (this.state.invoice_status === "Cancelled") return;
                        this.setState({ openPaidOfflineModal: true });
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave"
                        style={{
                          fontSize: "12px",
                          marginRight: "5px"
                        }}
                      ></i>{" "}
                      Paid Offline
                    </div>
                  )}
                {this.state.invoice_status === "Paid Offline" && (
                  <div
                    className="discount-total"
                    onClick={_ => {
                      if (this.state.invoice_status === "Cancelled") return;
                      this.setState({ openSetDraftModal: true });
                    }}
                  >
                    <i
                      className="fas fa-money-bill-wave"
                      style={{
                        fontSize: "12px",
                        marginRight: "5px"
                      }}
                    ></i>{" "}
                    Back to Draft
                  </div>
                )}
                <div>
                  {this.state.invoice_status === "Issued" ||
                    this.state.invoice_status === "Paid Offline" ? null : !this
                      .state.data?.invoice_discount ? (
                    <div
                      className="discount-total"
                      onClick={_ => {
                        if (this.state.invoice_status === "Cancelled") return;
                        this.setState({ openAddTotalDiscountModal: true });
                      }}
                    >
                      <i
                        className="fas fa-tag"
                        style={{
                          transform: "rotate(90deg)",
                          fontSize: "12px",
                          marginRight: "5px"
                        }}
                      ></i>{" "}
                      Add Discount to Invoice
                    </div>
                  ) : (
                    <div
                      className="discount-total"
                      onClick={_ => {
                        if (this.state.invoice_status === "Cancelled") return;
                        this.removeTotalDiscount();
                      }}
                    >
                      <i
                        className="fas fa-trash"
                        style={{ fontSize: "12px", marginRight: "5px" }}
                      ></i>{" "}
                      Remove Discount
                    </div>
                  )}
                  {this.state.invoice_status === "Issued" ||
                    this.state.invoice_status === "Paid Offline" ? null : !this
                      .state.data || parseInt(this.state.taxAmount) === 0 ? (
                    <div
                      className="discount-total"
                      style={{ paddingTop: "0px" }}
                      onClick={() => {
                        if (this.state.invoice_status === "Cancelled") return;
                        this.setState({ openAddTaxModal: true });
                      }}
                    >
                      <i
                        className="fas fa-tag"
                        style={{
                          transform: "rotate(90deg)",
                          fontSize: "12px",
                          marginRight: "5px"
                        }}
                      ></i>{" "}
                      Add Tax to Invoice
                    </div>
                  ) : (
                    <div
                      className="discount-total"
                      style={{ paddingTop: "0px" }}
                      onClick={() => {
                        if (this.state.invoice_status === "Cancelled") return;
                        this.removeTax();
                      }}
                    >
                      <i
                        className="fas fa-trash"
                        style={{ fontSize: "12px", marginRight: "5px" }}
                      ></i>{" "}
                      Remove Tax
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="order-receipt-footer"></div>
          </div>

          <p
            style={{
              fontSize: "8px",
              textAlign: "center",
              fontFamily: "monospace",
              color: "transparent"
            }}
          >
            {this.state.referenceNumber}
          </p>


          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "auto",
              marginBottom: "30px",
              justifyContent: "center"
            }}
          >
            {(!this.state.isUpdating || this.state.invoice_status === "Issued") && (
              <div
                className="add-item-btn"
                style={{
                  background: "#b2b8c7",
                  border: "1px solid #b2b8c7"
                }}
                onClick={this.saveAndGoBack}
              >
                Back & Save
              </div>
            )}
            {this.state.invoice_status !== "Cancelled" &&
              this.state.invoice_status !== "Issued" &&
              this.state.invoice_status !== "Paid Offline" ? (
              <>
                <div
                  className="add-item-btn"
                  name="send-test"
                  style={{
                    background: "#0431B0",
                    border: "1px solid #0431B0"
                  }}
                  onClick={_ => this.sendTestInvoiceEmailToPartner()}
                >
                  Send Test{" "}
                  <i
                    data-tip
                    data-for="send-test"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  />
                  <ReactTooltip
                    id={"send-test"}
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    This invoice will be sent to {this.global.dsUser.email} as
                    a test
                  </ReactTooltip>
                </div>
                {
                  this.state.isUpdating ? (
                    <div
                      className="add-item-btn"
                      style={{
                        background: "#0431B0",
                        border: "1px solid #0431B0",
                      }}
                      onClick={this.updateInvoice}
                    >
                      Update Invoice
                    </div>
                  ) : (
                    <div
                      className="add-item-btn"
                      style={{
                        background: "#0431B0",
                        border: "1px solid #0431B0",
                        cursor:
                          this.state.data &&
                            this.state.data.type === "Recurring" &&
                            ((this.state.data.recurring_frequency === "week" &&
                              isAfter(
                                subDays(new Date(this.state.data.due_date), 1),
                                new Date()
                              )) ||
                              (this.state.data.recurring_frequency === "bi-week" &&
                                isAfter(
                                  subDays(new Date(this.state.data.due_date), 2),
                                  new Date()
                                )) ||
                              (this.state.data.recurring_frequency === "month" &&
                                isAfter(
                                  subDays(new Date(this.state.data.due_date), 3),
                                  new Date()
                                )))
                            ? "not-allowed"
                            : "pointer"
                      }}
                      onClick={_ => {
                        if (
                          this.state.data &&
                          this.state.data.type === "Recurring" &&
                          ((this.state.data.recurring_frequency === "week" &&
                            isAfter(
                              subDays(new Date(this.state.data.due_date), 1),
                              new Date()
                            )) ||
                            (this.state.data.recurring_frequency === "bi-week" &&
                              isAfter(
                                subDays(new Date(this.state.data.due_date), 2),
                                new Date()
                              )) ||
                            (this.state.data.recurring_frequency === "month" &&
                              isAfter(
                                subDays(new Date(this.state.data.due_date), 3),
                                new Date()
                              )))
                        ) {
                          toast.error("Sorry, you cannot send this invoice yet");
                          return;
                        }

                        this.setState({ openSendEmailModal: true });
                      }}
                    >
                      Next: Send Invoice
                    </div>
                  )
                }
              </>
            ) : (
              this.state.invoice_status === "Issued" && (
                <div
                  className="add-item-btn"
                  name="edit"
                  style={{
                    background: "#0431B0",
                    border: "1px solid #0431B0"
                  }}
                  onClick={this.editInvoice}
                >
                  Edit Invoice {" "}
                  <i
                    data-tip
                    data-for="edit"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  />
                  <ReactTooltip
                    id={"edit"}
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    This will return the invoice to the drafting stage and hide the issued invoice from the customer until edits are completed.
                  </ReactTooltip>
                </div>
              )
            )}
          </div>


          <div className="invoice-message-container">
            <div className="invoice-scheduled-date-section">
              <div className="invoice-schedule-note">
                <i className="far fa-clock" style={{ marginRight: "7px" }}></i>{" "}
                {this.state.invoice_status === "Issued"
                  ? "Sent on"
                  : "Scheduled for"}{" "}
                {days[getDay(new Date(this.state.invoiceDate))]}{" "}
                {format(new Date(this.state.invoiceDate), "LLLL dd, yyyy")}
              </div>
            </div>
            <div className="invoice-scheduled-message-section">
              <div className="invoice-message">{this.state.message}</div>
            </div>
          </div>

          {this.state.data?.next_invoice_date &&
            this.state.data?.is_manual_stripe && (
              <div className="next-date-container invoice-scheduled-date-section">
                <div>
                  <i
                    className="far fa-clock"
                    style={{ marginRight: "7px" }}
                  ></i>{" "}
                  Next invoice:{" "}
                </div>
                <div style={{ flexGrow: "1", marginLeft: "10px" }}>
                  {this.state.editNextDate ? (
                    <DatePicker
                      selected={this.state.next_invoice_date}
                      onChange={date => {
                        console.log(date);
                        this.setState(
                          { next_invoice_date: date, editNextDate: false },
                          _ => this.updateInvoiceDate()
                        );
                      }}
                      minDate={minNextDate}
                      showYearDropdown
                      showMonthDropdown
                      // dateFormat="MMMM d, yyyy"
                      className="next-control"
                      placeholderText="Service Period"
                      disabled={!this.state.editNextDate}
                    />
                  ) : (
                    format(
                      new Date(this.state.data.next_invoice_date),
                      "LLLL dd, yyyy"
                    )
                  )}
                </div>
                {new Date(this.state.data.next_invoice_date) > new Date() && (
                  <IconButton
                    onClick={e =>
                      this.setState({ editNextDate: !this.state.editNextDate })
                    }
                    style={{ padding: "8px" }}
                  >
                    {this.state.editNextDate ? (
                      <Close style={{ color: "white" }} />
                    ) : (
                      <Edit style={{ color: "white" }} />
                    )}
                  </IconButton>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(InvoiceBuilder);
