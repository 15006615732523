import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { Switch } from "@material-ui/core";
import axios from "axios";
import FormPreviewModal from "../../../../components/modals/FormPreviewModal";
import Validators from "../../../../validators/validators";
import ReactTooltip from "react-tooltip";

const { isSelectedFormId } = Validators;

class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      forms: [],
      registrationForms: [],
      applicationForms: [],
      isAddChildChecked: false,
      openPreviewModal: false,
      isAdvancedOptionsChecked: false,
      customers: []
    };
  }
  handleChange = (e, v) => {
    // this.setGlobal({
    //   event: {
    //     ...this.global.event,
    //     [e]: v
    //   }
    // });

    if (e === "participant_type_parent") {
      if (!this.global.event.participant_type) {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent: v,
              student: true,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent: v,
              student:
                this.global.event.participant_type?.student === true
                  ? true
                  : false,
              volunteer:
                this.global.event.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_student") {
      if (!this.global.event.participant_type) {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent: true,
              student: v,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent:
                this.global.event.participant_type?.parent === true
                  ? true
                  : false,
              student: v,
              volunteer:
                this.global.event.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_volunteer") {
      if (!this.global.event.participant_type) {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent: true,
              student: true,
              volunteer: v
            }
          }
        });
      } else {
        this.setGlobal({
          event: {
            ...this.global.event,
            participant_type: {
              parent:
                this.global.event.participant_type?.parent === true
                  ? true
                  : false,
              student:
                this.global.event.participant_type?.student === true
                  ? true
                  : false,
              volunteer: v
            }
          }
        });
      }
    } else {
      this.setGlobal({
        event: {
          ...this.global.event,
          [e]: v
        }
      });
    }
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);

    // Get customers to check if events have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${this.global.event.id}`;
    const customers = await axios.get(customers_ep);

    this.setState({
      forms: programs.data.data,
      registrationForms: programs.data.data.filter(
        e =>
          (e.form_name === "Registration" || e.form_name === "Waiver") &&
          !e.isArchived
      ),
      applicationForms: programs.data.data.filter(
        e => e.is_enabled && e.form_name === "Application" && !e.isArchived
      ),
      customers: customers.data.data
    });
    this.setGlobal({ loading: false, lastAPICall: programs });
  }

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Application Required?
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={this.state.customers.length > 0 ? true : false}
                name="application_required"
                checked={!!this.global.event.application_required}
                onClick={_ =>
                  this.handleChange(
                    "application_required",
                    !this.global.event.application_required
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Select Checkout Form<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              {this.global.event.formId ? (
                <formTypes.Select
                  options={[
                    ...(() =>
                      !this.global.event.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.event.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              ) : (
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...(() =>
                      !this.global.event.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.event.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              )}
            </div>
            {/* {!this.global.event.application_required ? (
              <div className="col-7 d-flex">
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...this.state.registrationForms.map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.event.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                ></formTypes.Select>
              </div>
            ) : (
              <div className="col-7 d-flex">
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...this.state.applicationForms.map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.event.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                ></formTypes.Select>
              </div>
            )} */}
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Checkout Form</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`newapplications ${
                  !isSelectedFormId(this.global.event.formId)
                    ? "button-disabled"
                    : ""
                }`}
                style={{
                  // regular colour or greyed out depending on the select field
                  border: `1px solid ${
                    isSelectedFormId(this.global.event.formId)
                      ? "#7ea1ff"
                      : "#cccccc"
                  }`,
                  padding: "6px 20px",
                  color: isSelectedFormId(this.global.event.formId)
                    ? "#7ea1ff"
                    : "#cccccc",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  cursor: "pointer"
                }}
                onClick={_ => {
                  if (isSelectedFormId(this.global.event.formId)) {
                    this.setState({ openPreviewModal: true });
                  }
                }}
              >
                Preview
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Add Child
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={this.state.customers.length > 0 ? true : false}
                name="addChild"
                checked={!!this.global.event.addChild}
                onClick={_ =>
                  this.handleChange("addChild", !this.global.event.addChild)
                }
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Advanced Options
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                name="advanced_options"
                checked={this.state.isAdvancedOptionsChecked}
                onClick={e =>
                  this.setState({
                    isAdvancedOptionsChecked:
                      !this.state.isAdvancedOptionsChecked
                  })
                }
              />
            </div>
          </div>
          {/* <div className="row sub-row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "left",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Min Cancellation <br />
                (Prior to start date)
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="checkbox" />
            </div>
            <div
              className="col-5 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <formTypes.Select
                options={new Array(14)
                  .fill(1)
                  .map((_, k) => ({ label: `${k + 1} days`, value: k }))}
              ></formTypes.Select>
            </div>
          </div> */}
          {this.state.isAdvancedOptionsChecked && (
            <>
              <div className="row sub-row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Offline Payments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    disabled
                    type="checkbox"
                    checked={this.global.event.manual_invoices}
                    name="manual_invoices"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    color: "#535353",
                    fontFamily: "Open Sans, sans-serif"
                  }}
                >
                  Collect Invoices Offline
                </div>
              </div>
              
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Limit Same Participant
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.event.allow_same_enrollment}
                    name="allow_same_enrollment"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Do NOT allow the same Participant to apply / register more than once
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3
                    style={{ margin: 0, padding: 0 }}
                    className="sub-labels"
                  >
                    Transaction Cost{" "}
                    <i
                      data-tip
                      data-for="transaction_cost"
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id="transaction_cost"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      A smaller percentage will be passed on to customers
                    </ReactTooltip>
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    disabled={this.state.customers.length > 0 ? true : false}
                    type="checkbox"
                    checked={this.global.event.transaction_cost}
                    name="transaction_cost"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Share Transaction Fee
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Disable Add-to-Cart
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.event.isCartDisabled}
                    name="isCartDisabled"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Disables the ability for adding this item to the cart
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Participant Type
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.event.addChild ||
                      (this.global.event.participant_type &&
                        this.global.event.participant_type.parent) ||
                      !this.global.event.participant_type
                    }
                    disabled={this.global.event.addChild}
                    name="participant_type_parent"
                    // style={{ marginTop: "7px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Parent
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.event.addChild
                        ? false
                        : (this.global.event.participant_type &&
                            this.global.event.participant_type.student) ||
                          !this.global.event.participant_type
                    }
                    disabled={this.global.event.addChild}
                    name="participant_type_student"
                    // style={{ marginTop: "-20px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Student
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.event.addChild
                        ? false
                        : (this.global.event.participant_type &&
                            this.global.event.participant_type.volunteer) ||
                          !this.global.event.participant_type
                    }
                    disabled={this.global.event.addChild}
                    name="participant_type_volunteer"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Volunteer
                </div>
              </div>
            </>
          )}
          {/* <div className="row sub-row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Cancellation Policy
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={this.global.event.show_cancellation_policy}
                name="show_cancellation_policy"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
                className="input-fields"
              />
            </div>
            <div
              className="col-5 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <textarea
                name="cancellation_policy"
                rows="4"
                cols="50"
                style={{ fontSize: "12px" }}
                className="input-fields"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.event.cancellation_policy}
              />
            </div>
          </div> */}
        </form>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.openPreviewModal && (
          <FormPreviewModal
            selectedFormId={this.global.event.formId}
            onClose={_ => this.setState({ openPreviewModal: false })}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Additional Settings"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionFour;
