import React, { useState, useEffect, useGlobal } from "reactn";
import { canUserCreate } from "../../utilities/listingCreation";

import { Program18 } from "../../assets";
import axios from "axios";

import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";
import { IMAGE_DROPDOWN, SWITCH, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const CreateOnlineVirtualModal = ({ onClose, history, dispatchEvent }) => {
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [addChild, setAddChild] = useState(false);
  const [applicationRequired, setApplicationRequired] = useState(false);
  const [transactionCost, setTransactionCost] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [canUserMake, setCanUserMake] = useState(false);
  const [autoWithdraw, setAutoWithdraw] = useState(false);
  const [loading, setLoading] = useGlobal("loading");
  const [enrollments, setEnrollments] = useGlobal("enrollments");
  const [isRecurring, setIsRecurring] = useState(false);
  const [allowSameEnrollment, setAllowSameEnrollment] = useState(true);

  async function getPrograms() {
    const feature_category = "Program%20Activities";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      const unsortedPrograms = resp.data.data;
      const sortedPrograms = sortByKey(unsortedPrograms, "feature");
      const sortedProgramsWithImage = sortedPrograms.map(prog => {
        return { ...prog, image: Program18 };
      });
      setPrograms(sortedProgramsWithImage);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPrograms();

      // Check if user can create more listings.
      const resp = await canUserCreate("online");
      setCanUserMake(resp.success);
      setLoading(false);
    })();
  }, []);

  const getBaseModalFields = () => {
    const programType = {
      type: IMAGE_DROPDOWN,
      data: {
        name: "Program Type",
        required: true,
        placeholder: "Pick a type",
        handleChange: value => {
          setSelectedProgramCategory(value);
          if (value === "Single Sessions") {
            setApplicationRequired(true);
            setAutoWithdraw(false);
            setIsRecurring(false);
          }
        },
        choices: programs
          .filter(e => e.has_access)
          .map((e, i) => ({
            text: e.feature,
            value: e.feature,
            key: e.id,
            image: {
              bordered: true,
              src: e.image
            }
          })),
        value: selectedProgramCategory
      },
      validators: { validateSubmit: notEmptyString(selectedProgramCategory) }
    };

    const recurringPayment = {
      type: SWITCH,
      data: {
        name: "Recurring Payment",
        checked:
          selectedProgramCategory === "Single Sessions" ? false : isRecurring,
        isDisabled:
          selectedProgramCategory === "Single Sessions" ? true : false,
        handleClick: () => {
          if (isRecurring) {
            setIsRecurring(false);
            setAutoWithdraw(false);
          } else {
            setIsRecurring(true);
            setAutoWithdraw(true);
          }
        }
      }
    };

    const targetChildren = {
      type: SWITCH,
      data: {
        name: "Target Children",
        checked: addChild,
        handleClick: () => setAddChild(!addChild)
      }
    };

    const applicationRequiredField = {
      type: SWITCH,
      data: {
        name: "Application Required",
        checked: applicationRequired,
        handleClick: () => setApplicationRequired(!applicationRequired),
        isDisabled: selectedProgramCategory === "Single Sessions"
      }
    };

    const shareTransactionFee = {
      type: SWITCH,
      data: {
        name: "Share Transaction Fee",
        checked: transactionCost,
        handleClick: () => setTransactionCost(!transactionCost),
        info: "A small percentage fee will be passed on to customers"
      }
    };

    const autoPayMandatory = {
      type: SWITCH,
      data: {
        name: "Make Autopay Mandatory",
        checked: autoWithdraw,
        handleClick: () => setAutoWithdraw(!autoWithdraw),
        isDisabled: !isRecurring,
        info: "Auto withdraw payments from customers for recurring plans. Organizer will be incurring a Credit Card processing fee."
      }
    };

    const fields = [];
    fields.push(programType);
    fields.push(recurringPayment);
    fields.push(targetChildren);
    fields.push(applicationRequiredField);
    fields.push(shareTransactionFee);
    if (selectedProgramCategory !== "Single Sessions") {
      fields.push(autoPayMandatory);
    }

    return fields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: () => {
        setEnrollments([]);
        history.push({
          pathname: "/partners/online-virtual/setup",
          state: {
            selectedProgramCategory: selectedProgramCategory,
            applicationRequired: applicationRequired,
            transactionCost: transactionCost,
            addChild: addChild,
            autoWithdraw: autoWithdraw,
            isRecurring: isRecurring,
            allowSameEnrollment: allowSameEnrollment
          }
        });
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Create Online Virtual",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  const getLimitReachedModal = () => {
    const props = {
      title: "Create Online Virtual",
      fields: [
        {
          type: TITLE,
          data: { name: "You have reached your online virtual limit." }
        }
      ],
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
    return <BaseModal {...props} />;
  };

  if (canUserMake) {
    return <BaseModal {...getBaseModalProps()} />;
  } else {
    return getLimitReachedModal();
  }
};

export default CreateOnlineVirtualModal;
