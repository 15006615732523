import React from "reactn";
import { format } from "date-fns";

const FranchiserOrdersModal = ({ partnerOrg }) => {
  const dsUser = partnerOrg.user;
  const orderInfo = partnerOrg.orderInfo;
  console.log("partnerOrg: ", partnerOrg);
  const getInfoComponent =(amount, infoType) => {
    return (
      <p
      style={{
        color: "#a1a1a1",
        paddingBottom: "5px",
        fontStyle: "italic",
        fontSize: "0.9rem"
      }}
      >
        {infoType} (to-date):{" "}
        {infoType === "Refunds" 
          ? amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
          : amount.toLocaleString("en-US")
        }
      </p>
    );
  };

  return (
    <div className="boxes" style={{display: "flex", justifyContent:"space-between"}}>
      <div className="box" style={{marginRight: "0px"}}>
        <h2>PAYMENTS OWED</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <h3>{orderInfo ? orderInfo.pendingCount : 0}</h3>
          <p style={{paddingTop: "20px"}}>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box" style={{marginRight: "0px"}}>
        <h2>PAID ORDERS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <h3>{orderInfo ? orderInfo.paidCount : 0 }</h3>
          {getInfoComponent(orderInfo.failedCount || "0", "Failed Orders")}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box" style={{marginRight: "0px"}}>
        <h2>AVERAGE EARNINGS PER ORDER</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <h3>${
            (orderInfo && orderInfo.totalEarnings && orderInfo.paidCount 
              ? (orderInfo.totalEarnings / orderInfo.paidCount) 
              : 0
            ).toFixed(2)}
          </h3>
          <p style={{paddingTop: "20px"}}>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box" style={{marginRight: "0px"}}>
        <h2>TOTAL ORDERS EARNINGS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <h3>${(orderInfo ? orderInfo.totalEarnings : 0).toFixed(2)}</h3>
          {getInfoComponent(orderInfo.refunds || "0.00", "Refunds")}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FranchiserOrdersModal;