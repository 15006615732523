import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import { Program18 } from "../../assets";
import { canUserCreate } from "../../utilities/listingCreation";

import BaseModal from "./baseModal/BaseModal";
import { IMAGE_DROPDOWN, TITLE, SWITCH } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const CreateProgramModal = ({ onClose, history, dispatchEvent }) => {
  const [programs, setPrograms] = useState([]);
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [addChild, setAddChild] = useState(false);
  const [manualInvoices, setManualInvoices] = useState(false);
  const [applicationRequired, setApplicationRequired] = useState(false);
  const [transactionCost, setTransactionCost] = useState(false);
  const [displayManualInvoices, setDisplayManualInvoices] = useState(false);
  const [autoWithdraw, setAutoWithdraw] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [allowSameEnrollment, setAllowSameEnrollment] = useState(true);

  const [canUserMake, setCanUserMake] = useState(false);
  const [loading, setLoading] = useGlobal("loading");
  const [enrollments, setEnrollments] = useGlobal("enrollments");

  async function getPrograms() {
    const feature_category = "Program%20Activities";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      const unsortedPrograms = resp.data.data;
      const sortedPrograms = sortByKey(unsortedPrograms, "feature");
      const sortedProgramsWithImage = sortedPrograms.map(prog => {
        return { ...prog, image: Program18 };
      });

      console.log("sortedProgramsWithImage", sortedProgramsWithImage);

      setPrograms(sortedProgramsWithImage);
    }
  }

  async function checkManual() {
    const feature_category = "Customization";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      const customizationPermissions = resp.data.data.filter(
        e => e.feature === "Accept Manual Payments"
      );
      const checkManual =
        customizationPermissions.length > 0
          ? customizationPermissions[0].has_access
          : false;
      setDisplayManualInvoices(checkManual);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPrograms();
      await checkManual();

      // Check if user can create more listings.
      const resp = await canUserCreate("program");
      setCanUserMake(resp.success);
      setLoading(false);
    })();
  }, []);
  const handleSubmit = () => {
    setEnrollments([]);
    history.push({
      pathname: "/partners/programs/setup",
      state: {
        selectedProgramCategory: selectedProgramCategory,
        addChild: addChild,
        manualInvoices: manualInvoices,
        applicationRequired: applicationRequired,
        transactionCost: transactionCost,
        autoWithdraw: isRecurring && autoWithdraw,
        isRecurring: isRecurring,
        allowSameEnrollment: allowSameEnrollment
      }
    });
  };

  const getBaseModalFields = () => {
    const programTypeField = {
      type: IMAGE_DROPDOWN,
      data: {
        name: "Program Type",
        required: true,
        placeholder: "Pick a type",
        handleChange: value => {
          setSelectedProgramCategory(value);
          if (value === "Single Sessions") {
            setApplicationRequired(true);
            setAutoWithdraw(false);
            setIsRecurring(false);
          }
        },
        choices: programs
          .filter(e => e.has_access)
          .map(e => ({
            text: e.feature,
            value: e.feature,
            key: e.id,
            image: {
              bordered: true,
              src: e.image
            }
          })),
        value: selectedProgramCategory
      },
      validators: { validateSubmit: notEmptyString(selectedProgramCategory) }
    };

    const recurringPaymentsField = {
      type: SWITCH,
      data: {
        name: "Recurring Payments",
        checked:
          selectedProgramCategory === "Single Sessions" ? false : isRecurring,
        required: false,
        isDisabled:
          selectedProgramCategory === "Single Sessions" ? true : false,
        handleClick: () => {
          if (isRecurring) {
            setIsRecurring(false);
            setAutoWithdraw(false);
          } else {
            setIsRecurring(true);
            setAutoWithdraw(true);
          }
        }
      }
    };

    const targetChildrenField = {
      type: SWITCH,
      data: {
        name: "Target Children",
        checked: addChild,
        required: false,
        handleClick: () => setAddChild(!addChild)
      }
    };

    const applicationRequiredField = {
      type: SWITCH,
      data: {
        name: "Application Required",
        checked: applicationRequired,
        required: false,
        handleClick: () => setApplicationRequired(!applicationRequired),
        isDisabled: selectedProgramCategory === "Single Sessions"
      }
    };

    const autopayMandatoryField = {
      type: SWITCH,
      data: {
        name: "Autopay Mandatory",
        checked: autoWithdraw,
        required: false,
        handleClick: () => setAutoWithdraw(!autoWithdraw),
        isDisabled: !isRecurring || manualInvoices,
        info: "Auto withdraw payments from customers for recurring plans. Organizer will be incurring a Credit Card processing fee."
      }
    };

    const shareTransactionFeeField = {
      type: SWITCH,
      data: {
        name: "Share Transaction Fee",
        checked: transactionCost,
        required: false,
        handleClick: () => setTransactionCost(!transactionCost),
        isDisabled: manualInvoices,
        info: "A small percentage fee will be passed on to customers"
      }
    };

    const fields = [];

    fields.push(programTypeField);
    fields.push(recurringPaymentsField);
    fields.push(targetChildrenField);
    fields.push(applicationRequiredField);
    if (selectedProgramCategory !== "Single Sessions") {
      fields.push(autopayMandatoryField);
    }
    fields.push(shareTransactionFeeField);

    return fields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: handleSubmit,
      buttonColour: "#3B4EFF",
      textColour: "#fff",
      validatorKey: "validateSubmit"
    };
    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Create program",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose,
      height: "65vh",
      midSectionHeight: "52vh"
    };
  };

  const getLimitReachedModal = () => {
    const props = {
      title: "Create program",
      fields: [
        { type: TITLE, data: { name: "You have reached your program limit." } }
      ],
      handleClose: onClose
    };
    return <BaseModal {...props} />;
  };

  // user has reached their limit of programs they can create
  if (!canUserMake) {
    return getLimitReachedModal();
  }

  return <BaseModal {...getBaseModalProps()} />;
};

export default CreateProgramModal;
