import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import CC from "../Payments/stripe_component";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Close } from "@material-ui/icons";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import BaseModal from "./baseModal/BaseModal";
import {
  GET_HTML,
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE, YELLOW_BUTTON } from "./baseModal/colours";
class AddBillingDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.required = [{ name: "creditToken", validation: e => !e }];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.loading) return; // Prevent double submission

    if (!this.state.creditToken) {
      toast.error(`Please fill out the form!`);
      return;
    }

    // Add loading state and disable button
    this.setState({ loading: true });

    if (this.props.user.accountType === "Partner") {
      const endp = `${process.env.REACT_APP_API}/adm/partner/billing`;
      const result = await axios.put(endp, this.state);
      console.log(result);
      if (result.data.success) {
        if (result.data.data.sources.data[0].cvc_check !== "pass") {
          toast.error("Incorrect CVC.");
          this.setState({ loading: false });
          return;
        }
        toast.success("Credit Card Updated");
        this.props.updateCC();
        this.props.onClose();
      } else {
        toast.error(result.data.message);
        this.setState({ loading: false });
      }
    } else {
      const endp = `${process.env.REACT_APP_API}/adm/franchise/billing`;
      const result = await axios.put(endp, this.state);
      if (result.data.success) {
        toast.success("Credit Card Updated");
        this.props.updateCC();
        this.props.onClose();
      } else {
        toast.error(result.data.message);
        this.setState({ loading: false });
      }
    }
  }

documentModal = () => {
  return (
    <div
    className="form-group"
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 0
    }}
  >
    <label
      htmlFor="displayName"
      style={{ width: "29%", textAlign: "left", 
      fontSize: "1.15rem",
      fontFamily: "futura-pt, sans-serif",
      fontWeight: "500",
      color: "#626262" }}
    >
      Credit Card <span style={{ color: "rgb(255, 0, 0)" }}> *</span>:
    </label>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
      <Elements>
        <CC setToken={t => this.setState({ creditToken: t })} />
      </Elements>
    </StripeProvider>
  </div>
  )
}
  getBaseModalFields() {
    const documentInput = {
      type: GET_HTML,
      data: {
        gethtml: this.documentModal
      }
    };

    const fields = [];
    fields.push(documentInput);

    return fields;
  }

  getBaseModalButtons = () => {
    return this.state.loading ?
      (
        [
          {
            name: "Saving...",
            buttonColour: "#7481ff",
            textColour: WHITE,
            validatorKey: "validateSubmit",
            handleClick: () => {},
          }
        ]
      ) : (
        [
          {
            name: "Save",
            buttonColour: BLUE_BUTTON,
            textColour: WHITE,
            validatorKey: "validateSubmit",
            handleClick: e => this.submitForm(e)
          }
        ]
      )
  };

  getBaseModalProps = () => {
    return {
      title: "Add/Edit Billing Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: _ => this.props.onClose(),
      height: "27vh",
      width: "500px"
    };
  };


  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Add/Edit Billing Details</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Credit Card *:
                </label>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                  <Elements>
                    <CC setToken={t => this.setState({ creditToken: t })} />
                  </Elements>
                </StripeProvider>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn profile-btn" disabled={this.state.loading}>
                  Save
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddBillingDetails);
