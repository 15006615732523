import Validators from "../validators";

const ValidateOrganization = children => {
  if (!children) return false;
  const fields = {
    organization_title: [Validators.isEmpty],
    address1: [Validators.isEmpty],
    city: [Validators.isEmpty],
    province: [Validators.isEmpty],
    postalcode: [Validators.isEmpty],
    country: [Validators.isEmpty],
    description: [Validators.isNotEnough100]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateAddDetails = children => {
  if (!children) return false;
  const fields = {
    program_types: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateLikedBankAccount = children => {
  if (!children) return false;
  const fields = {
    stripeId: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateOrgCustomization = children => {
  if (!children) return false;
  const fields = {
    timezone: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateEventsOne = children => {
  if (!children) return false;
  const fields = {
    event_title: [Validators.isEmpty],
    event_start: [Validators.isEmpty],
    event_end: [Validators.isEmpty],
    address1: [Validators.isEmpty],
    event_image: [Validators.isEmpty],
    description: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateEventsTwo = children => {
  if (!children) return false;
  const fields = {
    tickets: [Validators.activePlansExist]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateEventsThree = children => {
  if (!children) return false;
  const fields = {
    formId: [Validators.isEmpty, Validators.isEmptyFormSelect]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateGiftCards = children => {
  if (!children) return false;
  const fields = {
    amount: [Validators.isEmpty],
    gift_name: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateMembershipOne = children => {
  if (!children) return false;
  const fields = {
    membership_name: [Validators.isEmpty],
    membership_type: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateMembershipThree = children => {
  if (!children) return false;
  const fields = {
    prices: [Validators.activePlansExist]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateNews = children => {
  if (!children) return false;
  const fields = {
    title: [Validators.isEmpty],
    mainbody: [Validators.isEmpty],
    date: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateMembershipTwo = children => {
  if (!children) return false;
  const fields = {
    formId: [Validators.isEmpty, Validators.isEmptyFormSelect]
    // prices: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateProgramsOne = children => {
  if (!children) return false;
  const fields = {
    program_category: [Validators.isEmpty],
    program_name: [Validators.isEmpty],
    program_details: [Validators.isEmpty],
    description: [Validators.isEmpty],
    program_color: [Validators.isEmptyNonZero]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateProgramsTwo = children => {
  if (!children) return false;
  const fields = {
    address1: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateProgramsThree = children => {
  // console.log("children", children);
  if (!children) return false;
  const fields = {
    days_of_week: [Validators.isEmpty]
  };
  if (children.program_details === "Semester" || children.program_details === "Workshops / Classes" || children.program_details === "Camps") {
    fields.number_of_weeks = [Validators.programDuration];
  }

  // Make timing mandatory for Lessons (but nothing else)
  if (children.program_details === "Lessons / Sessions") {
    // TYPE COERCION IS INTENTIONAL BECAUSE children.timing MIGHT BE A STRING
    if ((children.timing[1] == -1 || children.timing[0] == -1) && !children.customTimes) {
      return false;
    }
    fields.number_of_weeks = [Validators.programDuration];
  } else {
    if (children.timing[1] == -1 || children.timing[0] == -1) {
      return false;
    }
  }

  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateProgramsFour = children => {
  if (!children) return false;
  const fields = {
    plans: [Validators.activePlansExist]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateProgramsFive = children => {
  if (!children) return false;
  const fields = {
    formId: [Validators.isEmpty, Validators.isEmptyFormSelect]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateVirtualOne = children => {
  if (!children) return false;
  const fields = {
    program_category: [Validators.isEmpty],
    program_name: [Validators.isEmpty],
    description: [Validators.isEmpty],
    program_color: [Validators.isEmptyNonZero]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateVirtualTwo = children => {
  if (!children) return false;
  const fields = {
    meeting_password: [Validators.isEmpty],
    meeting_alias: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateVirtualManual = children => {
  if (!children) return false;
  const fields = {
    manual_meeting_password: [Validators.isEmpty],
    // manual_meeting_alias: [Validators.isEmpty],
    manual_metting_link: [Validators.isEmpty],
    manual_meeting_id: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidateVirtualThree = children => {
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  // console.log("children", children);
  if (!children) return false;
  // TYPE COERCION IS INTENTIONAL BECAUSE children.timing MIGHT BE A STRING
  const missingCustomTime = () => {
    for(const day of days) {
      if(children.customTimes[day]?.includes(-1)) {
        return true;
      }
    }
    return false;
  }
  if ((children.timing[1] == -1 || children.timing[0] == -1) && (children.enableCustomTimes && missingCustomTime())) {
    return false;
  } else if (children.timing[1] == -1 || children.timing[0] == -1) {
    return false
  }
  // if (children.program_type === 0) return false;

  const fields = {
    days_of_week: [Validators.isEmpty],
    program_type: [Validators.programType]
  };



  if (!children.isRecurring && children.program_type != 0) {  // Do not validate number_of_weeks for single classes
    fields.number_of_weeks = [Validators.programDuration];
  }

  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateVirtualFour = children => {
  if (!children) return false;
  const fields = {
    formId: [Validators.isEmpty, Validators.isEmptyFormSelect],
    plans: [Validators.activePlansExist]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidatePromotionsOne = children => {
  if (!children) return false;
  const fields = {
    title: [Validators.isEmpty],
    description: [Validators.isEmpty],
    discountType: [Validators.isEmpty],
    discountAmount: [Validators.isEmpty],
    selectedPlans: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidatePartiesOne = children => {
  if (!children) return false;
  const fields = {
    party_title: [Validators.isEmpty],
    party_start: [Validators.isEmpty],
    party_end: [Validators.isEmpty],
    address1: [Validators.isEmpty],
    party_image: [Validators.isEmpty],
    description: [Validators.isEmpty],
    duration: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const ValidatePartiesTwo = children => {
  if (!children) return false;
  const fields = {
    tickets: [Validators.activePlansExist]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidatePartiesThree = children => {
  if (!children) return false;
  const fields = {
    formId: [Validators.isEmpty, Validators.isEmptyFormSelect]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const Profile = {
  ValidateOrganization,
  ValidateAddDetails,
  ValidateLikedBankAccount,
  ValidateProgramsOne,
  ValidateProgramsTwo,
  ValidateProgramsThree,
  ValidateProgramsFour,
  ValidateProgramsFive,
  ValidateEventsOne,
  ValidateEventsTwo,
  ValidateEventsThree,
  ValidatePartiesOne,
  ValidatePartiesTwo,
  ValidatePartiesThree,
  ValidateMembershipOne,
  ValidateMembershipTwo,
  ValidateMembershipThree,
  ValidateNews,
  ValidateGiftCards,
  ValidateVirtualOne,
  ValidateVirtualTwo,
  ValidateVirtualThree,
  ValidateVirtualFour,
  ValidateVirtualManual,
  ValidateOrgCustomization,
  ValidatePromotionsOne
};

export default Profile;
