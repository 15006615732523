import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
// import { CloudUpload } from "@material-ui/icons";
import FormPreviewModal from "../../../../components/modals/FormPreviewModal";
import ReactTooltip from "react-tooltip";
import Validators from "../../../../validators/validators";

const { isSelectedFormId } = Validators;

class SectionFive extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forms: [],
      registrationForms: [],
      applicationForms: [],
      isAdvancedOptionsChecked: false,
      isAddChildChecked: false,
      openPreviewModal: false,
      customers: []
    };
  }
  handleChange = (e, v) => {
    // this.setGlobal({
    //   online: {
    //     ...this.global.online,
    //     [e]: v
    //   }
    // });

    if (e === "participant_type_parent") {
      if (!this.global.online.participant_type) {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent: v,
              student: true,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent: v,
              student:
                this.global.online.participant_type?.student === true
                  ? true
                  : false,
              volunteer:
                this.global.online.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_student") {
      if (!this.global.online.participant_type) {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent: true,
              student: v,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent:
                this.global.online.participant_type?.parent === true
                  ? true
                  : false,
              student: v,
              volunteer:
                this.global.online.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_volunteer") {
      if (!this.global.online.participant_type) {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent: true,
              student: true,
              volunteer: v
            }
          }
        });
      } else {
        this.setGlobal({
          online: {
            ...this.global.online,
            participant_type: {
              parent:
                this.global.online.participant_type?.parent === true
                  ? true
                  : false,
              student:
                this.global.online.participant_type?.student === true
                  ? true
                  : false,
              volunteer: v
            }
          }
        });
      }
    } else {
      this.setGlobal({
        online: {
          ...this.global.online,
          [e]: v
        }
      });
    }

    console.log("GLOBAL", this.global);
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);

    // Get customers to check if events have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/${this.global.online.id}`;
    const customers = await axios.get(customers_ep);

    this.setState({
      forms: programs.data.data,
      registrationForms: programs.data.data.filter(
        e =>
          (e.form_name === "Registration" || e.form_name === "Waiver") &&
          !e.isArchived
      ),
      applicationForms: programs.data.data.filter(
        e => e.is_enabled && e.form_name === "Application" && !e.isArchived
      ),
      customers: customers.data.data
    });
    this.setGlobal({ loading: false, lastAPICall: programs });
  }

  onSwitchChange(event) {
    this.setState({
      isAdvancedOptionsChecked: !this.state.isAdvancedOptionsChecked
    });
  }

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Application Required?
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={
                  this.state.customers.length > 0 ||
                  this.global.online.program_category === "Single Sessions"
                }
                name="application_required"
                checked={!!this.global.online.application_required}
                onClick={_ =>
                  this.handleChange(
                    "application_required",
                    !this.global.online.application_required
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Select Checkout Form<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              {this.global.online.formId ? (
                <formTypes.Select
                  options={[
                    ...(() =>
                      !this.global.online.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.online.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              ) : (
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...(() =>
                      !this.global.online.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.online.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Checkout Form</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`newapplications ${
                  !isSelectedFormId(this.global.online.formId)
                    ? "button-disabled"
                    : ""
                }`}
                style={{
                  // regular colour or greyed out depending on the select field
                  border: `1px solid ${
                    isSelectedFormId(this.global.online.formId)
                      ? "#7ea1ff"
                      : "#cccccc"
                  }`,
                  padding: "6px 20px",
                  color: isSelectedFormId(this.global.online.formId)
                    ? "#7ea1ff"
                    : "#cccccc",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (isSelectedFormId(this.global.online.formId)) {
                    this.setState({ openPreviewModal: true });
                  }
                }}
              >
                Preview
              </div>
              {/* <button
                className="newapplications"
                style={{
                  border: "1px solid #7ea1ff",
                  padding: "6px 20px",
                  color: "#7ea1ff",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans"
                }}
              >
                Edit
              </button> */}
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Add Child
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                name="addChild"
                checked={!!this.global.online.addChild}
                disabled={this.state.customers.length > 0 ? true : false}
                onClick={_ =>
                  this.handleChange("addChild", !this.global.online.addChild)
                }
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Advanced Options
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                name="advanced_options"
                checked={this.state.isAdvancedOptionsChecked}
                onClick={e => this.onSwitchChange(e)}
              />
            </div>
          </div>

          {this.state.isAdvancedOptionsChecked && (
            <div>
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Program Restrictions
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.members_only_program}
                    name="members_only_program"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Members only program
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Open Registrations
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.open_registration}
                    name="open_registration"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Keep Registrations open
                </div>
              </div> */}
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Roster Format
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.online.number_of_weeks > 0 ||
                      this.global.online.manual_invoices
                    }
                    name="attendance_roaster"
                    disabled={
                      this.global.online.number_of_weeks === 0 ||
                      !this.global.online.manual_invoices
                    }
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Attendance : Present / Absent
                </div>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  {/* empty heading */}
                  <h3
                    style={{ margin: 0, padding: 0 }}
                    className="sub-labels"
                  ></h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    name="check_in_roaster"
                    style={{ marginTop: "7px" }}
                    disabled
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Check-in / Check-out Time
                </div>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  {/* Empty heading */}
                  <h3 style={{ margin: 0, padding: 0 }}></h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.online.number_of_weeks === 0 &&
                      !this.global.online.manual_invoices
                    }
                    disabled={
                      this.global.online.number_of_weeks > 0 ||
                      this.global.online.manual_invoices
                    }
                    name="check_in_roaster"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Advanced Attendance: Impacts Billing
                </div>
              </div>

              {/* Removed on April 5, 2022, as it was not used in any backend code and caused crashes. */}
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Assignments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    disabled
                    checked={this.global.online.program_assignments}
                    name="program_assignments"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Enable Assignments
                </div>
              </div> */}

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Recurring Payments?
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.isRecurring}
                    name="isRecurring"
                    disabled={true}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Is Recurring?
                </div>
              </div>

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Transaction Cost{" "}
                    <i
                      data-tip
                      data-for="transaction_cost"
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id="transaction_cost"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      A small percentage will be passed on to customers
                    </ReactTooltip>
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    disabled={this.state.customers.length > 0 ? true : false}
                    type="checkbox"
                    checked={this.global.online.transaction_cost}
                    name="transaction_cost"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Share Transaction Fee
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Offline Payments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.manual_invoices}
                    name="manual_invoices"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                    disabled={true}
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Collect Invoices Offline
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Make Autopay Mandatory
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    disabled
                    type="checkbox"
                    checked={this.global.online.auto_withdraw}
                    name="manual_invoices"
                    // onChange={e =>
                    //   this.handleChange(e.target.name, e.target.checked)
                    // }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Automatically collect payments for recurring plans.
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Disable EFT Payments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.isEftDisabled}
                    name="isEftDisabled"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Disable EFT Debit payments, which are direct bank transfers
                  that can take several days to process.
                </div>
              </div>

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Limit Same Participant
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.allow_same_enrollment}
                    name="allow_same_enrollment"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Do NOT allow the same Participant to apply / register more than once
                </div>
              </div>

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Disable Add-to-Cart
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.isCartDisabled}
                    name="isCartDisabled"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Disables the ability for adding this item to the cart
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  {console.log("OV", this.global.online)}
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Participant Type
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.online.addChild ||
                      (this.global.online.participant_type &&
                        this.global.online.participant_type.parent) ||
                      !this.global.online.participant_type
                    }
                    disabled={this.global.online.addChild}
                    name="participant_type_parent"
                    // style={{ marginTop: "7px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Parent
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.online.addChild
                        ? false
                        : (this.global.online.participant_type &&
                            this.global.online.participant_type.student) ||
                          !this.global.online.participant_type
                    }
                    disabled={this.global.online.addChild}
                    name="participant_type_student"
                    // style={{ marginTop: "-20px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Student
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.online.addChild
                        ? false
                        : (this.global.online.participant_type &&
                            this.global.online.participant_type.volunteer) ||
                          !this.global.online.participant_type
                    }
                    disabled={this.global.online.addChild}
                    name="participant_type_volunteer"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Volunteer
                </div>
              </div>
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Min Cancellation <br />
                    (Prior to start date)
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.min_cancellation}
                    name="min_cancellation"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <formTypes.Select
                    options={new Array(14)
                      .fill(1)
                      .map((_, k) => ({ label: `${k + 1} days`, value: k }))}
                  ></formTypes.Select>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Cancellation Policy
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.online.show_cancellation_policy}
                    name="show_cancellation_policy"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <textarea
                    className="input-text-fields"
                    name="cancellation_policy"
                    rows="4"
                    cols="50"
                    style={{ fontSize: "12px" }}
                    value={this.global.online.cancellation_policy}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div> */}
            </div>
          )}
        </form>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.openPreviewModal && (
          <FormPreviewModal
            selectedFormId={this.global.online.formId}
            onClose={_ => this.setState({ openPreviewModal: false })}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Online Checkout"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionFive;
